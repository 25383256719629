import React from "react";
import "../Main.css";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import regression from "regression";

export const msg_unauthorized_admin_login = "Obehörig att logga in som admin";
export const msg_successful_operation = "Operationen lyckades";
export const msg_unauthorized_operation = "Behörighet saknas för denna åtgärd";
export const msg_not_part_of_study = "är inte med i studien";
export const msg_this_user_not_part_of_study =
  "Denna användare är inte med i studien";
export const msg_user_unknown = "Okänd användare";
export const msg_enter_email = "Du måste fylla i din e-postadress";
export const msg_enter_email_and_pass =
  "Du måste fylla i både e-postadress och kod";
export const msg_email_unknown = "Ogiltig e-postadress";
export const msg_user_iqoro_mismatch = "iQoro tillhör inte denna användare";
export const msg_user = "Användaren";
export const msg_doesnt_exist = "finns inte";
export const msg_is_not_admin = "är inte admin";
export const msg_no_data_for_period = "Det finns ingen data för perioden";
export const msg_no_new_files =
  "Det finns inga nya filer på iQoro sedan förra uppladdningen";
export const msg_contact_support =
  "Vänligen kontakta MYoroface support på info@iqoro.com";
export const msg_unit_no_bluetooth_support =
  "Enheten har inget stöd för Bluetooth";
export const msg_browser_no_bluetooth_api_support =
  "Dataöverföring stöds ej utav denna webbläsare";
export const msg_data_read_error = "Fel vid hämtning av data från iQoro";
export const msg_no_users = "Det finns inga användare";
export const msg_no_admins = "Det finns inga administratörer";
export function msgContactSupport(error_code) {
  return (
    'Ett oväntat fel uppstod: "' + error_code + '". ' + msg_contact_support
  );
}

export function errorText(error_msg, error_codes_and_texts) {
  if (error_msg.message) {
    error_msg = error_msg.message;
  }

  let final_msg = error_msg;
  let predictable_error_found = false;
  error_codes_and_texts.forEach((error_code_and_text) => {
    if (error_msg.substr(0, 3) === error_code_and_text.code) {
      final_msg = error_code_and_text.text;
      predictable_error_found = true;
    }
  });
  if (!predictable_error_found) {
    final_msg = msgContactSupport(error_msg);
  }
  return {
    msg: final_msg,
    type: "error",
  };
}

function predictableError(error_msg, error_codes_and_texts) {
  if (error_msg.message) {
    error_msg = error_msg.message;
  }

  for (let i = 0; i < error_codes_and_texts.length; i++) {
    if (error_msg.substr(0, 3) === error_codes_and_texts[i].code) {
      return true;
    }
  }
  return false;
}

export function errorHandling(
  operation,
  operation_arg,
  toasterSetter,
  toaster_msg,
  spinnerSetter,
  retries = 0
) {
  new Promise(() => {
    const wait = () =>
      new Promise((resolve) => {
        setTimeout(() => resolve(), 1000);
      });
    // call function recursively, also convert arg array into function argument list (with apply)
    operation.apply(this, operation_arg).catch((error_msg) => {
      // retransmissions only if retries not exhausted & received error is not predictable
      if (retries > 0 && !predictableError(error_msg, toaster_msg)) {
        return wait().then(
          errorHandling.bind(
            null,
            operation,
            operation_arg,
            toasterSetter,
            toaster_msg,
            spinnerSetter,
            retries - 1
          )
        );
      }
      spinnerSetter(false);
      toasterSetter(errorText(error_msg, toaster_msg));
      //return  toasterSetter(errorText(error_msg, toaster_msg));
    });
  });
}

export const enter_email = "Skriv e-postadress";

export function setFocus(el) {
  document.getElementById(el).focus();
}

export function onTab(e, id_base, ix, total_ix) {
  if (e.code === "Tab") {
    if (ix === "last") {
      e.preventDefault();
    } else if (ix < total_ix - 1) {
      e.preventDefault();
      const next_ix = ix + 1;
      set_cursor_at_end(document.getElementById(id_base + next_ix));
    }
  }
}

export function set_cursor_at_end(elem) {
  set_current_cursor_position(elem.innerText.length, elem);
}

export function set_current_cursor_position(chars, elem) {
  function _createRange(node, chars, range) {
    if (!range) {
      range = document.createRange();
      range.selectNode(node);
      range.setStart(node, 0);
    }

    if (chars.count === 0) {
      range.setEnd(node, chars.count);
    } else if (node && chars.count > 0) {
      if (node.nodeType === Node.TEXT_NODE) {
        if (node.textContent.length < chars.count) {
          chars.count -= node.textContent.length;
        } else {
          range.setEnd(node, chars.count);
          chars.count = 0;
        }
      } else {
        for (var lp = 0; lp < node.childNodes.length; lp++) {
          range = _createRange(node.childNodes[lp], chars, range);

          if (chars.count === 0) {
            break;
          }
        }
      }
    }

    return range;
  }

  if (chars >= 0) {
    var selection = window.getSelection();

    let range = _createRange(elem, { count: chars });

    if (range) {
      range.collapse(false);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }
}

export function check_if_event_within_elements(event, elements) {
  let targetElement = event.target; // clicked element

  // The element(s) to check for enclosed click doesn't exist in the DOM (yet).
  if (elements[0] === null) {
    return false;
  }

  do {
    if (elements.includes(targetElement)) {
      // This is a click inside.
      return true;
    }

    // Go up the DOM
    targetElement = targetElement.parentNode;
  } while (targetElement);

  // This is a click outside.
  return false;
}

export function validateEmail(email_candidate) {
  const mail_format = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  if (email_candidate.match(mail_format)) {
    return true;
  } else {
    return false;
  }
}

export function trimContent(id_base, ix) {
  if (document.getElementById(id_base + ix).innerText.length > 0) {
    document.getElementById(id_base + ix).innerText = document
      .getElementById(id_base + ix)
      .innerText.trim();
  }
}

export function date_time_formatter(date) {
  return date_formatter(date) + "  " + time_formatter(date);
}

export function date_formatter(date) {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString();
  const day = date.getDate().toString();
  const date_separator = "-";

  return (
    year +
    date_separator +
    month.padStart(2, "0") +
    date_separator +
    day.padStart(2, "0")
  );
}

export function time_formatter(date) {
  let hour = date.getHours().toString();
  let minutes = date.getMinutes().toString();
  let seconds = date.getSeconds().toString();

  const time_separator = ":";

  return (
    hour.padStart(2, "0") +
    time_separator +
    minutes.padStart(2, "0") +
    time_separator +
    seconds.padStart(2, "0")
  );
}

export function time_formatter_short(date) {
  let hour = date.getHours().toString();
  let minutes = date.getMinutes().toString();

  const time_separator = ":";

  return hour.padStart(2, "0") + time_separator + minutes.padStart(2, "0");
}

export function setHoursRegardlessSummerTime(date) {
  if (date.getTimezoneOffset() === -120) {
    date.setHours(1, 0, 0);
  } else {
    date.setHours(0, 0, 0);
  }
  return new Date(date);
}

export function RadioButtonFilter({ button_list, setter_function, state_var }) {
  return (
    <div className="all-users-filter-outer">
      {button_list.map((button) => (
        <div className="all-users-filter-separator" key={button.var}>
          <div
            className="all-users-filter"
            onClick={() => setter_function(button.var)}
          >
            {state_var === button.var ? (
              <div className="radio-button">
                <RadioButtonCheckedIcon />
              </div>
            ) : (
              <div className="radio-button">
                <RadioButtonUncheckedIcon />
              </div>
            )}
            <div className="vertical-middle">{button.display}</div>
          </div>
        </div>
      ))}
    </div>
  );
}

export function get_octets_from_stream(stream, start, length) {
  let octets = "";
  for (let k = start + length - 1; k >= start; k--) {
    octets += stream.substring(2 * k, 2 * k + 2);
  }
  return octets;
}

export function create_octets_for_stream(integer, length) {
  if (integer < 0) {
    integer += 65536;
  }
  const octets_normal_direction = integer
    .toString(16)
    .padStart(2 * length, "0");

  let octets_for_stream = "";
  for (let k = length - 1; k >= 0; k--) {
    octets_for_stream += octets_normal_direction.substring(2 * k, 2 * k + 2);
  }

  return octets_for_stream;
}

function get_bitstring_from_stream(stream, start, length) {
  const bits_in_octet = 8;
  const bitstring_overpadded =
    "0".repeat(length * bits_in_octet) +
    parseInt(get_octets_from_stream(stream, start, length), 16).toString(2);

  return bitstring_overpadded.substring(
    bitstring_overpadded.length - length * bits_in_octet
  );
}

function is_bit_set_in_string(stream, nr) {
  return stream[stream.length - 1 - nr] === "1" ? true : false;
}

export function data_ok(full_octet_stream) {
  const header_data_present = get_bitstring_from_stream(
    full_octet_stream,
    2,
    2
  );

  const high_rate_sample_data_present = get_bitstring_from_stream(
    full_octet_stream,
    4,
    2
  );

  const start_datetime_exists = is_bit_set_in_string(header_data_present, 0);
  const force_sequence_exists = is_bit_set_in_string(
    high_rate_sample_data_present,
    1
  );

  return start_datetime_exists && force_sequence_exists;
}

export function decoder_start_datetime(full_octet_stream) {
  const time_stamp_bin = get_bitstring_from_stream(full_octet_stream, 32, 4);

  const year = parseInt(time_stamp_bin.substring(0, 6), 2) + 2022;
  const month = parseInt(time_stamp_bin.substring(6, 10), 2)
    .toString()
    .padStart(2, "0");
  const day = parseInt(time_stamp_bin.substring(10, 15), 2)
    .toString()
    .padStart(2, "0");
  const seconds_of_day = parseInt(time_stamp_bin.substring(15), 2);
  const hours = Math.floor(seconds_of_day / 3600)
    .toString()
    .padStart(2, "0");
  const minutes = Math.floor((seconds_of_day % 3600) / 60)
    .toString()
    .padStart(2, "0");
  const seconds = (seconds_of_day % 60).toString().padStart(2, "0");

  return new Date(year, month - 1, day, hours, minutes, seconds).toISOString();
}

export function decoder_iqoro_id(full_octet_stream) {
  return get_octets_from_stream(full_octet_stream, 14, 8);
}

export function encoder_datetime(datetime) {
  const datetime_obj = new Date(datetime);
  const temp_int =
    (datetime_obj.getFullYear() - 2022) * Math.pow(2, 26) +
    datetime_obj.getMonth() * Math.pow(2, 22) +
    datetime_obj.getDate() * Math.pow(2, 17) +
    datetime_obj.getHours() * 3600 +
    datetime_obj.getMinutes() * 60 +
    datetime_obj.getSeconds();

  let temp_oct = temp_int.toString(16).padStart(8, "0");
  let final_oct = "";

  for (let i = 3; i >= 0; i--) {
    final_oct += temp_oct.substring(i * 2, i * 2 + 2);
  }
  return final_oct;
}

export function decoder_force_sequence(full_octet_stream) {
  const header_data_present = get_bitstring_from_stream(
    full_octet_stream,
    2,
    2
  );

  const sample_rate =
    parseInt(get_octets_from_stream(full_octet_stream, 10, 2), 16) / 10;

  const nr_of_samples = parseInt(
    get_octets_from_stream(full_octet_stream, 28, 2),
    16
  );

  const sample_data_start =
    32 +
    is_bit_set_in_string(header_data_present, 0) * 4 +
    is_bit_set_in_string(header_data_present, 6) * 2 +
    is_bit_set_in_string(header_data_present, 7) * 2 +
    is_bit_set_in_string(header_data_present, 8) * 2 +
    is_bit_set_in_string(header_data_present, 10) * 2;

  function create_force_sequence(stream, start, length) {
    let force_sequence = [];
    for (let i = 0; i < length; i++) {
      const force_int16 =
        stream.substring(start * 2 + i * 4 + 2, start * 2 + i * 4 + 4) +
        stream.substring(start * 2 + i * 4, start * 2 + i * 4 + 2);

      let force = parseInt(force_int16, 16);

      // Force is two complement encoded
      if (force > 32767) {
        force -= 65536;
      }

      // Force is stored in dN, transform into N
      force /= 10;
      force_sequence.push(force);
    }

    return force_sequence;
  }

  const force_sequence = create_force_sequence(
    full_octet_stream,
    sample_data_start,
    nr_of_samples
  );

  return { sample_rate, force_sequence };
}

//force_sequence_int ex: [0, -0.1, 0.1, 0.4, 0.7, 0.9, 1, 2, 3, 4.3, 4, 4.6, 5]
export function generate_iqoro_log_stream(start_datetime, force_sequence_int) {
  const sample_frequency_int = 10;
  const sample_frequency_int_dHz = sample_frequency_int * 10;
  const bytes_0_9 = "4301c10302000000c103";
  const sample_frequency = create_octets_for_stream(
    sample_frequency_int_dHz,
    2
  );
  const bytes_12_13 = "0000";
  const iqoro_id = "efcdab8967452301";
  const bytes_22_27 = "436501000000";
  const sample_length = create_octets_for_stream(force_sequence_int.length, 2);
  const bytes_30_31 = "0000";
  const start_time = encoder_datetime(start_datetime);
  const start_temps_and_battery = "9a083808d607bc7a";
  const force_sequence = force_sequence_int
    .map((force) => create_octets_for_stream(force * 10, 2))
    .join("");
  const end_time_decoded = new Date(start_datetime);
  end_time_decoded.setSeconds(
    end_time_decoded.getSeconds() +
      force_sequence_int.length / sample_frequency_int
  );
  const end_time_encoded = encoder_datetime(end_time_decoded);
  const end_temps_and_battery = "9b083a08d907a87a";

  return (
    bytes_0_9 +
    sample_frequency +
    bytes_12_13 +
    iqoro_id +
    bytes_22_27 +
    sample_length +
    bytes_30_31 +
    start_time +
    start_temps_and_battery +
    force_sequence +
    end_time_encoded +
    end_temps_and_battery
  );
}

////////////////////////////////////////////////////
//    Algorithm for temperature compensation      //
////////////////////////////////////////////////////

export function tempComp(force_sequence) {
  var nneg;
  var npos;
  // Setup of some parameters
  let MAlen_edge = 25; // Number of samples to average over for edge detection
  let MAlen_rest = 5; // Number of samples to average over for rest detection

  //const WF=0.25;   //  width factor
  const RF = 0.02; // rest factor - at which part of the peak derivative do we change between rest/edge
  const AF = 0.08; // active factor - at which part of the peak derivative do we change between edge/active (we are less picky in regarding what is activity)
  const startSamples = 10; // number of samples that we throw away from the head
  const endSamples = 10; // number of samples that we throw away from the tail
  const maxSpread = 2; // max factor between largest and smallest edge peak to keep all edges
  const minPeakDist = 40; // minimum distance between peaks that are considered candidates
  //const DFL =0.1; // Decision factor level - decides below witch relative level the figure of merit will cause candidate exclusion

  // differentiate and filter the signal to get indication of positions of pronounced level change
  // The filter is a simple moving average filter that is run in both forward
  // and reverse direction to get a filtering without a phase shift
  let edgeSig = MY_MAfiltfilt(diff(force_sequence), MAlen_edge);
  let flatSig = MY_MAfiltfilt(diff(force_sequence), MAlen_rest);

  // Look for positive peaks in the edge signal (i.e. distinct shifts form low to high level)
  let posSig = [];
  edgeSig.forEach((x) => {
    if (x >= 0) posSig.push(x);
    else posSig.push(0);
  });

  let pos_locs = MYsimpleFindPeaks(posSig, minPeakDist);
  //form a figure of merit by multiplying the width of the peaks with their prominence
  let merit = arrayIndex(edgeSig, pos_locs);

  let I = argsort(merit).reverse();
  let Dp = arrayIndex(merit, I);

  // Check if all peaks seem to be valid
  if (Math.max(...Dp) / Math.min(...Dp) < maxSpread) {
    // keep all
    npos = Dp.length;
  } else {
    // search for the best split between wanted and unwanted peaks and
    let vp = [];
    for (let ng = 2; ng < Dp.length; ng++) {
      vp[ng] = variance(Dp.slice(0, ng)) + variance(Dp.slice(ng, Dp.length));
    }
    vp[Dp.length] = variance(Dp);
    npos = vp.indexOf(Math.min(...vp.slice(2, vp.length)));
  }

  // Throw away all peaks with figure of merit below the threshold
  I = I.slice(0, npos);
  I = I.sort((a, b) => a - b); //Put back in normal index order
  //let pos_pks = arrayIndex(edgeSig,I); // TODO remove?
  pos_locs = arrayIndex(pos_locs, I);

  // Repeat the process to identify the distinct falling edges in the signal
  let negSig = [];
  edgeSig.forEach((x) => {
    if (x >= 0) {
      negSig.push(0);
    } else {
      negSig.push(-x);
    }
  });
  let neg_locs = MYsimpleFindPeaks(negSig, minPeakDist);
  // form a figure of merit by multiplying the width of the peaks with their prominence
  merit = arrayIndex(negSig, neg_locs);

  I = argsort(merit).reverse();
  let Dn = arrayIndex(merit, I);

  // Check if all peaks seem to be valid
  if (Math.max(...Dn) / Math.min(...Dn) < maxSpread) {
    // keep all
    nneg = Dn.length;
  } else {
    // search for the best split between wanted and unwanted peaks and
    let vn = [];
    for (let ng = 2; ng < Dn.length; ng++) {
      vn[ng] = variance(Dn.slice(0, ng)) + variance(Dn.slice(ng, Dn.length));
    }
    vn[Dn.length] = variance(Dn);
    nneg = vn.indexOf(Math.min(...vn.slice(2, vn.length)));
  }

  // Throw away all peaks with figure of merit below the threshold
  I = I.slice(0, nneg);
  I = I.sort((a, b) => a - b); // put back in normal index order
  //let pos_pks = arrayIndex(edgeSig,I); // TODO remove?
  neg_locs = arrayIndex(neg_locs, I);

  // Debug code to point out when the pulse detection has failed
  if (nneg < 0 || npos < 0 || nneg != npos || neg_locs[1] < pos_locs[1]) {
    throw new Error("Otydlig dragning, temperaturkompensering ej möjlig");
  }

  /* Possible improvements
   * check pn-order
   * check widths
   * check number of peaks
   */

  // Search for the boundaries of the resting periods
  let restl = [];
  let resth = [];
  restl[0] = 0;
  resth[npos] = force_sequence.length;
  // Start with prefilling  the boundaries with the edge positions
  for (let n = 0; n < npos; n++) {
    resth[n] = pos_locs[n];
    restl[n + 1] = neg_locs[n];
  }

  resth[0] = pos_locs[0];
  for (let n = 0; n < npos; n++) {
    let pth = RF * flatSig[pos_locs[n]];
    while (flatSig[resth[n]] > pth && resth[n] > restl[n]) {
      resth[n] = resth[n] - 1;
    }
    let nth = RF * flatSig[neg_locs[n]];
    while (flatSig[restl[n + 1]] < nth && restl[n + 1] < resth[n + 1]) {
      restl[n + 1] = restl[n + 1] + 1;
    }
  }

  // Search for the boundaries of the active periods
  let actl = [];
  let acth = [];
  // Start with prefilling  the boundaries with the edge positions
  for (let n = 0; n < npos; n++) {
    actl[n] = pos_locs[n];
    acth[n] = neg_locs[n];
  }

  for (let n = 0; n < npos; n++) {
    let pth = AF * flatSig[actl[n]];
    while (flatSig[actl[n]] > pth && actl[n] < acth[n]) {
      actl[n] = actl[n] + 1;
    }
    let nth = AF * flatSig[acth[n]];
    while (flatSig[acth[n]] < nth && acth[n] > actl[n]) {
      acth[n] = acth[n] - 1;
    }
  }

  // Gather an unequally spaced vector that contains an initial flat part
  // followed by an (assumed) exponential part corresponding to an (assumed)
  // unloaded sensor

  // limit the number of samples at the start
  if (resth[0] - startSamples < 0) restl[0] = 0;
  else restl[0] = resth[0] - startSamples;

  // limit the number of samples at the end
  if (restl[npos] + endSamples > force_sequence.length - 1)
    resth[npos] = force_sequence.length - 1;
  else resth[npos] = restl[npos] + endSamples;

  let fit_data = [];
  for (let i = 0; i <= npos; i++) {
    for (let j = restl[i]; j <= resth[i]; j++) {
      fit_data.push([j, force_sequence[j]]);
    }
  }

  //%% Fit a third degree polynomial to the resting signal
  const poly = regression.polynomial(fit_data, { order: 3, precision: 17 });
  //    model.fit(fit_data, [3]);

  //evaluate the polynomial for the duration of the original signal and correct it
  //round it to 2 decimals
  let corrected = [];
  for (let i = 0; i < force_sequence.length; i++) {
    let corrected_sample = force_sequence[i] - poly.predict(i)[1]; //model.estimate(3,i));
    let corrected_sample_rounded = corrected_sample.toFixed(2);
    corrected.push(corrected_sample_rounded);
  }
  return corrected;
}

const sum = function (x) {
  return x.reduce((a, b) => a + b, 0);
};

const diff = function (signal) {
  let out = Array(signal.length - 1);
  for (let i = 1; i < signal.length; i++) {
    out[i - 1] = signal[i] - signal[i - 1];
  }
  return out;
};

const mean = function (signal) {
  return sum(signal) / signal.length;
};

const arrayIndex = function (arr, indexArray) {
  let ans = [];
  indexArray.forEach((i) => {
    let val = arr[i];
    ans.push(val);
  });
  return ans;
};

const variance = function (signal) {
  if (signal.length <= 1) return 0;
  else {
    let m = mean(signal);
    let acc = 0;
    for (let i = 0; i < signal.length; i++) {
      acc += (signal[i] - m) * (signal[i] - m);
    }
    return acc / (signal.length - 1);
  }
};

// https://stackoverflow.com/a/65410414/10694594
let decor = (v, i) => [v, i]; // combine index and value as pair
let undecor = (pair) => pair[1]; // remove value from pair
const argsort = (arr) =>
  arr
    .map(decor)
    .sort((a, b) => a[0] - b[0])
    .map(undecor);

/**
 * Get indices of all local maxima in a sequence.
 * @param {number[]} xs - sequence of numbers
 * @returns {number[]} indices of local maxima
 */
function find_local_maxima(xs) {
  let maxima = [];
  // iterate through all points and compare direct neighbors
  for (let i = 1; i < xs.length - 1; ++i) {
    if (xs[i] > xs[i - 1] && xs[i] >= xs[i + 1]) maxima.push(i);
  }
  return maxima;
}

/**
 * Remove peaks that are too close to higher ones.
 * @param {number[]} indices - indices of peaks in xs
 * @param {number[]} xs - original signal
 * @param {number} dist - minimum distance between peaks
 * @returns {number[]} filtered peak index list
 */
function filter_by_distance(indices, xs, dist) {
  let to_remove = Array(indices.length).fill(false);
  let heights = indices.map((i) => xs[i]);
  let sorted_index_positions = argsort(heights).reverse();

  // adapted from SciPy find_peaks
  for (let current of sorted_index_positions) {
    if (to_remove[current]) {
      continue; // peak will already be removed, move on.
    }

    let neighbor = current - 1; // check on left side of current peak
    while (neighbor >= 0 && indices[current] - indices[neighbor] < dist) {
      to_remove[neighbor] = true;
      --neighbor;
    }

    neighbor = current + 1; // check on right side of current peak
    while (
      neighbor < indices.length &&
      indices[neighbor] - indices[current] < dist
    ) {
      to_remove[neighbor] = true;
      ++neighbor;
    }
  }
  return indices.filter((v, i) => !to_remove[i]);
}
const MYsimpleFindPeaks = function (signal, minDist) {
  let indices = find_local_maxima(signal);
  return filter_by_distance(indices, signal, minDist);
};

const MY_MAfiltfilt = function (signal, len) {
  //MY_MAFILTFILT filters the signal with an MA (moving average) filter in both

  //forward and backward direction
  //   signal is
  //   len is the moving average span in samples
  //   the function results in a gain of len^2 (which could be fixed by a
  //   scalar multiplcation at the end if it was a concern )
  //   To conserve the slope at the ends the signals head and tail are mirrored
  //   returns a row vector

  let L = signal.length;
  // filter in forward direction first to an intermediate half-filterd signal
  let temp = Array(L).fill(0);

  // Calculate the first intermediate sample summing the first len samples
  temp[0] += sum(signal.slice(0, len));

  // loop through  the rest of the signal extrapolated with the last sample
  // value
  for (let i = 1; i <= L - len; i++) {
    temp[i] = temp[i - 1] + signal[i + len - 1] - signal[i - 1];
  }

  // Continue with the tail, using the mirrored version of the tail of the input signal
  for (let i = L - len + 1; i < L; i++) {
    temp[i] =
      temp[i - 1] +
      2 * signal[L - 1] -
      signal[2 * L - i - len - 1] -
      signal[i - 1];
  }

  // Now do the same in the backwards direction
  //% allocate the resulting
  let filteredSig = Array(L).fill(0);

  filteredSig[L - 1] = sum(temp.slice(L - len, L));

  // loop through  the rest of the signal extrapolated with the last sample value
  for (let i = L - 2; i >= len - 1; i--) {
    filteredSig[i] = filteredSig[i + 1] + temp[i - len + 1] - temp[i + 1];
  }

  // Continue with the head, using the mirrored version of the head of the input signal
  let head = temp[0];
  for (let i = len - 1; i >= 1; i--) {
    head = head + 2 * signal[0] - signal[len - i] - signal[i];
    filteredSig[i - 1] = filteredSig[i] + head - temp[i];
  }

  return filteredSig;
};
