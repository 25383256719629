import React, { useState, useEffect } from "react";
import "../../Main.css";
import { useIQoroStore } from "../../Stores/General";
import {
  GetAdmins,
  AddAdmin,
  DeleteAdmin,
  RegisterUser,
} from "../../API/Admin";
import {
  setFocus,
  set_cursor_at_end,
  onTab,
  trimContent,
  enter_email,
  msg_unauthorized_operation,
  msg_doesnt_exist,
  msg_user,
  msg_successful_operation,
  msg_is_not_admin,
  msg_no_admins,
  errorHandling,
} from "../Util";
import WarningIcon from "@mui/icons-material/Warning";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import ButtonGroup from "@mui/material/ButtonGroup";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Divider from "@mui/material/Divider";

export function HandleAdmins() {
  const [setToasterMessage, token, setShowSpinner, setShowToaster] =
    useIQoroStore((state) => [
      state.setToasterMessage,
      state.token,
      state.setShowSpinner,
      state.setShowToaster,
    ]);
  const [new_admin_to_add, setNewAdminToAdd] = useState(false);
  const [new_admin_to_delete, setNewAdminToDelete] = useState(false);
  const [nr_of_admins_to_add, setNrOfAdminsToAdd] = useState(0);
  const [nr_of_admins_to_delete, setNrOfAdminsToDelete] = useState(0);
  const [show_all_admins, setShowAllAdmins] = useState(false);
  const [admins, setAdmins] = useState(null);
  const [admins_to_add, setAdminsToAdd] = useState([]);
  const [admins_to_delete, setAdminsToDelete] = useState([]);
  const [show_add_admins, setShowAddAdmins] = useState(false);
  const [show_delete_admins, setShowDeleteAdmins] = useState(false);
  const [show_confirm_inactivation, setShowConfirmInactivation] =
    useState(false);
  const [show_confirm_register, setShowConfirmRegister] = useState("");
  const [button_variant_1, setButtonVariant1] = useState("outlined");
  const [button_variant_2, setButtonVariant2] = useState("outlined");
  const [button_variant_3, setButtonVariant3] = useState("outlined");

  useEffect(() => {
    if (new_admin_to_add) {
      if (document.getElementById(`add-admin-${nr_of_admins_to_add - 1}`)) {
        document.getElementById("add-admin-last").blur();
        setFocus(`add-admin-${nr_of_admins_to_add - 1}`);
        document.getElementById(
          `add-admin-${nr_of_admins_to_add - 1}`
        ).innerText = document.getElementById("add-admin-last").innerText;
        document.getElementById("add-admin-last").innerText = "";
        set_cursor_at_end(
          document.getElementById(`add-admin-${nr_of_admins_to_add - 1}`)
        );
        setNewAdminToAdd(false);
      }
    } else if (new_admin_to_delete) {
      if (
        document.getElementById(`delete-admin-${nr_of_admins_to_delete - 1}`)
      ) {
        document.getElementById("delete-admin-last").blur();
        setFocus(`delete-admin-${nr_of_admins_to_delete - 1}`);
        document.getElementById(
          `delete-admin-${nr_of_admins_to_delete - 1}`
        ).innerText = document.getElementById("delete-admin-last").innerText;
        document.getElementById("delete-admin-last").innerText = "";
        set_cursor_at_end(
          document.getElementById(`delete-admin-${nr_of_admins_to_delete - 1}`)
        );
        setNewAdminToDelete(false);
      }
    }
  }, [new_admin_to_add, new_admin_to_delete]);

  useEffect(() => {
    if (admins) {
      setTimeout(() => {
        setShowAllAdmins(true);
      }, 10);
    }
  }, [admins]);

  function onGetAdminsIfClosed() {
    if (!show_all_admins) {
      setButtonVariant1("contained");
      errorHandling(
        onGetAdmins,
        [],
        setToasterMessage,
        [{ code: "403", text: msg_unauthorized_operation }],
        setShowSpinner,
        2
      );
    } else {
      setShowAllAdmins(false);
      setButtonVariant1("outlined");
    }
  }

  function onGetAdmins() {
    setShowSpinner(true);
    setShowToaster(false);
    return GetAdmins(token).then((data) => {
      if (data.status === 200) {
        setShowSpinner(false);
        let admins_temp = [];
        for (const x in data) {
          x !== "status" && admins_temp.push(data[x]);
        }
        setAdmins(admins_temp);
      } else {
        throw new Error(data.status + ": " + data.message);
      }
    });
  }

  function onAddAdmins() {
    setShowSpinner(true);
    setShowToaster(false);
    for (let i = 0; i < nr_of_admins_to_add; i++) {
      onAddAdmin(
        document.getElementById("add-admin-" + i).innerText,
        i === nr_of_admins_to_add - 1
      );
    }
  }

  function onAddAdmin(user, is_last) {
    AddAdmin(user, token)
      .then((data) => {
        if (data.status === 200) {
          if (is_last) {
            setShowSpinner(false);
            setToasterMessage({
              msg: msg_successful_operation,
              type: "success",
            });
            setNrOfAdminsToAdd(0);
            setShowAllAdmins(false);
          }
        } else {
          const msg = data.message
            ? data.status + ": " + data.message
            : data.status;
          throw new Error(msg);
        }
      })
      .catch((err) => {
        setShowSpinner(false);
        const err_code = err.substr(0, 3);
        if (err_code === "403") {
          setToasterMessage({
            msg: msg_unauthorized_operation,
            type: "error",
          });
        } else if (err_code === "404") {
          setShowConfirmRegister(user);
        } else {
          setToasterMessage({
            msg: err,
            type: "error",
          });
        }
      });
  }

  function onRegisterUser() {
    setShowSpinner(true);
    setShowToaster(false);
    setShowConfirmRegister("");
    RegisterUser(show_confirm_register)
      .then((data) => {
        //HTTP 201
        if (data.length === 0) {
          setShowSpinner(false);
          setToasterMessage({
            msg: msg_successful_operation,
            type: "success",
          });
          onAddAdmin(show_confirm_register);
        } else {
          const msg = data.message
            ? data.status + ": " + data.message
            : data.status;
          throw new Error(msg);
        }
      })
      .catch((err) => {
        setShowSpinner(false);
        setToasterMessage({
          msg: err,
          type: "error",
        });
      });
  }

  function onDeleteAdmins() {
    setShowSpinner(true);
    setShowToaster(false);
    setShowConfirmInactivation(false);
    for (let i = 0; i < nr_of_admins_to_delete; i++) {
      errorHandling(
        onDeleteAdmin,
        [i],
        setToasterMessage,
        [
          { code: "403", text: msg_unauthorized_operation },
          {
            code: "404",
            text:
              document.getElementById("delete-admin-" + i).innerText +
              " " +
              msg_is_not_admin,
          },
        ],
        setShowSpinner
      );
    }
  }

  function onDeleteAdmin(index) {
    return DeleteAdmin(
      document.getElementById("delete-admin-" + index).innerText,
      token
    ).then((data) => {
      if (data.status === 200) {
        if (index === nr_of_admins_to_delete - 1) {
          setShowSpinner(false);
          setToasterMessage({
            msg: msg_successful_operation,
            type: "success",
          });
          setNrOfAdminsToDelete(0);
          setShowAllAdmins(false);
        }
      } else {
        const msg = data.message
          ? data.status + ": " + data.message
          : data.status;
        throw new Error(msg);
      }
    });
  }

  function onChangeAdminsLast() {
    if (document.getElementById("add-admin-last").innerText.length > 0) {
      setNrOfAdminsToAdd(nr_of_admins_to_add + 1);
      setNewAdminToAdd(true);
    }
  }

  function onChangeDeleteAdminsLast() {
    if (document.getElementById("delete-admin-last").innerText.length > 0) {
      setNrOfAdminsToDelete(nr_of_admins_to_delete + 1);
      setNewAdminToDelete(true);
    }
  }

  function onBlurUsers(id_base, ix) {
    if (document.getElementById(id_base + ix).innerText.length === 0) {
      let nr_of_users = 0;
      if (id_base.substr(0, 3) === "add") {
        setNrOfAdminsToAdd(nr_of_admins_to_add - 1);
        nr_of_users = nr_of_admins_to_add;
      } else {
        setNrOfAdminsToDelete(nr_of_admins_to_delete - 1);
        nr_of_users = nr_of_admins_to_delete;
      }
      for (let i = ix; i < nr_of_users - 1; i++) {
        document.getElementById(id_base.concat(i)).innerText =
          document.getElementById(id_base.concat(i + 1)).innerText;
      }
    }
  }

  function saveAdminsToAdd() {
    let admins_to_add_temp = [];
    for (let i = 0; i < nr_of_admins_to_add; i++) {
      admins_to_add_temp.push(
        document.getElementById("add-admin-" + i).innerText
      );
    }
    setAdminsToAdd(admins_to_add_temp);
  }

  function saveAdminsToDelete() {
    let admins_to_delete_temp = [];
    for (let i = 0; i < nr_of_admins_to_delete; i++) {
      admins_to_delete_temp.push(
        document.getElementById("delete-admin-" + i).innerText
      );
    }
    setAdminsToDelete(admins_to_delete_temp);
  }

  function ConfirmInactivation() {
    return (
      <div className="confirm-box">
        <div className="confirm-box-top">
          <WarningIcon />
          <div className="confirm-box-text">
            Vill du verkligen inaktivera dessa administratörer?
          </div>
        </div>
        <div className="confirm-box-buttons">
          <div className="confirm-box-button">
            <div className="button" onClick={onDeleteAdmins}>
              Ja
            </div>
          </div>
          <div className="confirm-box-button">
            <div
              className="button"
              onClick={() => setShowConfirmInactivation(false)}
            >
              Nej
            </div>
          </div>
        </div>
      </div>
    );
  }

  function ConfirmRegister() {
    return (
      <div className="confirm-box">
        <div className="confirm-box-top">
          <WarningIcon />
          <div className="confirm-box-text">
            Användare {show_confirm_register} finns inte. Vill du skapa
            användaren och samtidigt aktivera den som admin?
          </div>
        </div>
        <div className="confirm-box-buttons">
          <div className="confirm-box-button">
            <div className="button" onClick={onRegisterUser}>
              Ja
            </div>
          </div>
          <div className="confirm-box-button">
            <div className="button" onClick={() => setShowConfirmRegister("")}>
              Nej
            </div>
          </div>
        </div>
      </div>
    );
  }

  const theme = createTheme({
    palette: {
      neutral: {
        main: "#646464",
        contrastText: "#fff",
      },
    },
  });

  function showAddAdmins() {
    if (show_add_admins) {
      saveAdminsToAdd();
      setButtonVariant2("outlined");
    } else {
      setButtonVariant2("contained");
    }
    if (show_delete_admins) {
      saveAdminsToDelete();
      setButtonVariant3("outlined");
    }
    setShowAddAdmins(!show_add_admins);
    setShowDeleteAdmins(false);
  }

  function showDeleteAdmins() {
    if (show_delete_admins) {
      saveAdminsToDelete();
      setButtonVariant3("outlined");
    } else {
      setButtonVariant3("contained");
    }
    if (show_add_admins) {
      saveAdminsToAdd();
      setButtonVariant2("outlined");
    }
    setShowDeleteAdmins(!show_delete_admins);
    setShowAddAdmins(false);
  }

  const buttons1 = [
    <ThemeProvider theme={theme}>
      <Button
        key="one"
        onClick={onGetAdminsIfClosed}
        color="neutral"
        variant={button_variant_1}
      >
        Lista
      </Button>
    </ThemeProvider>,
  ];
  const buttons2 = [
    <ThemeProvider theme={theme}>
      <Button
        key="two"
        onClick={showAddAdmins}
        color="neutral"
        variant={button_variant_2}
      >
        Aktivera
      </Button>
    </ThemeProvider>,
    <ThemeProvider theme={theme}>
      <Button
        key="three"
        onClick={showDeleteAdmins}
        color="neutral"
        variant={button_variant_3}
      >
        Inaktivera
      </Button>
    </ThemeProvider>,
  ];

  useEffect(() => {
    if (show_all_admins && admins && admins.length === 0) {
      setToasterMessage({
        msg: msg_no_admins,
        type: "info",
      });
    }
  }, [show_all_admins, admins]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          "& > *": {
            m: 1,
          },
        }}
      >
        <ButtonGroup size="small" aria-label="small button group">
          {buttons1}
        </ButtonGroup>
      </Box>
      {admins && admins.length > 0 && (
        <div
          className={
            show_all_admins
              ? "all-users-list-outest max-height-all-admins"
              : "all-users-list-outest max-height-0"
          }
          style={{
            "--nr-of-users": admins.length + 1,
          }}
        >
          <div className="all-users-list-outer">
            <div className="all-users-list cursor-text">
              <div className="table-header-admin">ADMINISTRATÖRER</div>
            </div>
            {admins.map((admin, ix) => (
              <div key={`admin-${ix}`} className="all-users-list cursor-text">
                {admin}
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="divider-mui">
        <Divider />
      </div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          "& > *": {
            m: 1,
          },
        }}
      >
        <ButtonGroup size="small" aria-label="small button group">
          {buttons2}
        </ButtonGroup>
      </Box>
      {show_add_admins && (
        <div className="add-users-list-outer">
          <div className="one-px-height" />
          {[...Array(nr_of_admins_to_add).keys()].map((nr) => (
            <div
              id={`add-admin-${nr}`}
              key={`add-admin-${nr}`}
              className="add-users-list no-border-bottom"
              contentEditable="true"
              suppressContentEditableWarning={true}
              onClick={() => setFocus(`add-admin-${nr}`)}
              onKeyDown={(e) => onTab(e, "add-admin-", nr, nr_of_admins_to_add)}
              spellCheck="false"
              onBlur={() => {
                trimContent("add-admin-", nr);
                onBlurUsers("add-admin-", nr);
              }}
            >
              {admins_to_add[nr]}
            </div>
          ))}
          <div
            id="add-admin-last"
            className="add-users-list placeholder-text"
            contentEditable="true"
            suppressContentEditableWarning={true}
            onClick={() => setFocus("add-admin-last")}
            onKeyUp={() => onChangeAdminsLast()}
            onKeyDown={(e) =>
              onTab(e, "add-admin-", "last", nr_of_admins_to_add)
            }
            spellCheck="false"
            placeholder={enter_email}
          />
          <div className="admin-form-button">
            <div
              className="button"
              onClick={() => nr_of_admins_to_add > 0 && onAddAdmins()}
            >
              Aktivera
            </div>
          </div>
        </div>
      )}

      {show_delete_admins && (
        <div className="add-users-list-outer">
          <div className="one-px-height" />
          {[...Array(nr_of_admins_to_delete).keys()].map((nr) => (
            <div
              id={`delete-admin-${nr}`}
              key={`delete-admin-${nr}`}
              className="add-users-list no-border-bottom"
              contentEditable="true"
              suppressContentEditableWarning={true}
              onClick={() => setFocus(`delete-admin-${nr}`)}
              onKeyDown={(e) =>
                onTab(e, "delete-admin-", nr, nr_of_admins_to_delete)
              }
              spellCheck="false"
              onBlur={() => {
                trimContent("delete-admin-", nr);
                onBlurUsers("delete-admin-", nr);
              }}
            >
              {admins_to_delete[nr]}
            </div>
          ))}
          <div
            id="delete-admin-last"
            className="add-users-list placeholder-text"
            contentEditable="true"
            suppressContentEditableWarning={true}
            onClick={() => setFocus("delete-admin-last")}
            onKeyUp={() => onChangeDeleteAdminsLast()}
            onKeyDown={(e) =>
              onTab(e, "delete-admin-", "last", nr_of_admins_to_delete)
            }
            spellCheck="false"
            placeholder={enter_email}
          />
          <div className="admin-form-button">
            <div
              className="button"
              onClick={() =>
                nr_of_admins_to_delete > 0 && setShowConfirmInactivation(true)
              }
            >
              Inaktivera
            </div>
          </div>
        </div>
      )}
      {show_confirm_inactivation && <ConfirmInactivation />}
      {show_confirm_register.length > 0 && <ConfirmRegister />}

      <div
        className={
          show_confirm_inactivation || show_confirm_register.length > 0
            ? "blur-background blur-background-intro"
            : "blur-background blur-background-outro"
        }
      />
    </>
  );
}
