import { CustomRequest } from "./Rest";

export async function PostData(user, id, contents_and_datetimes) {
  return await CustomRequest(
    "POST",
    "/study/data/" + user + "/" + id,
    undefined,
    undefined,
    contents_and_datetimes
  );
}
