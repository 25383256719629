import React, { forwardRef, useState, useEffect } from "react";
import "./Main.css";
import { UserVerify } from "./Pages/User/Login";
import { useIQoroStore } from "./Stores/General";
import PersonIcon from "@mui/icons-material/Person";
import { AdminLogin } from "./Pages/Admin/Login";
import { Admin } from "./Pages/Admin/Main";
import { User, UserConnectIQoroAndTransfer } from "./Pages/User/Main";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";

const pic12 = "/images/i-qoro-brand.webp";

function App() {
  const [current_page] = useIQoroStore((state) => [state.current_page]);

  return (
    <div className="App">
      <Header />
      <Title />
      {current_page === "user-verify" ? (
        <UserVerify />
      ) : current_page === "user" ? (
        <User />
      ) : current_page === "user-connect-transfer" ? (
        <UserConnectIQoroAndTransfer />
      ) : current_page === "admin-login" ? (
        <AdminLogin />
      ) : (
        current_page === "admin" && <Admin />
      )}
      <Toaster />
      <Spinner />
    </div>
  );
}

function Header() {
  const [username_pressed, setUsernamePressed] = useState(false);
  const [logging_out, setLoggingOut] = useState(false);

  const [
    current_user,
    setCurrentUser,
    setCurrentPage,
    setToken,
    setActiveStep,
    setTransferredFilesRatio,
  ] = useIQoroStore((state) => [
    state.current_user,
    state.setCurrentUser,
    state.setCurrentPage,
    state.setToken,
    state.setActiveStep,
    state.setTransferredFilesRatio,
  ]);

  function onLogoutClick() {
    setCurrentPage("user-verify");
    setUsernamePressed(false);
    setCurrentUser("");
    setLoggingOut(true);
    setToken("");
    setActiveStep(0);
    setTransferredFilesRatio(0);

    setTimeout(() => setLoggingOut(false), 500);
  }
  return (
    <>
      <div className="top-menu">
        {current_user.length > 0 && (
          <div
            className="login"
            onClick={() => setUsernamePressed(!username_pressed)}
          >
            <PersonIcon className="user-icon" />
            <span className="login-text">{current_user}</span>
          </div>
        )}
      </div>
      <div
        className={
          username_pressed
            ? "user-choice-menu"
            : "user-choice-menu user-choice-menu-up"
        }
      >
        {(current_user.length > 0 || logging_out) && (
          <div className="user-choice" onClick={onLogoutClick}>
            Logga ut
          </div>
        )}
      </div>
    </>
  );
}

function Title() {
  return (
    <div className="header">
      <div className="header-block">
        <div className="header-first">MYoroface</div>
        <img className="logo" src={pic12} alt="MYoroface logo"></img>
      </div>
      <div className="title">STUDIE FÖR SÖMNAPNÉ</div>
    </div>
  );
}

function Toaster() {
  const [toaster_messages, show_toaster, setShowToaster] = useIQoroStore(
    (state) => [
      state.toaster_messages,
      state.show_toaster,
      state.setShowToaster,
    ]
  );

  useEffect(() => {
    if (
      toaster_messages.length > 0 &&
      toaster_messages[toaster_messages.length - 1].msg.length > 0
    ) {
      setShowToaster(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toaster_messages]);

  const toaster_position = {
    vertical: "bottom",
    horizontal: "center",
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowToaster(false);
  };

  let duration = 3500;
  switch (
    toaster_messages[toaster_messages.length - 1] &&
    toaster_messages[toaster_messages.length - 1].type
  ) {
    case "error":
      duration = 8000;
      break;
    case "info":
      duration = 6000;
      break;
    case "success":
      duration = 3500;
      break;
    default:
      duration = 3500;
  }

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        open={show_toaster}
        autoHideDuration={duration}
        onClose={handleClose}
        anchorOrigin={toaster_position}
      >
        <Alert
          onClose={handleClose}
          severity={
            toaster_messages[toaster_messages.length - 1] &&
            toaster_messages[toaster_messages.length - 1].type
          }
          sx={{ width: "100%" }}
        >
          {toaster_messages[toaster_messages.length - 1] &&
            toaster_messages[toaster_messages.length - 1].msg}
        </Alert>
      </Snackbar>
    </Stack>
  );
}

function Spinner() {
  const [show_spinner] = useIQoroStore((state) => [state.show_spinner]);
  return (
    <>
      {show_spinner && (
        <div className="spinner">
          <CircularProgress />
        </div>
      )}
    </>
  );
}
export default App;
