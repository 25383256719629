import { CustomRequest } from "./Rest";

export async function SubmitLogin(username, password) {
  return await CustomRequest("POST", "/authenticate", undefined, undefined, {
    username: username,
    password: password,
  });
}

export async function SubmitVerify(username) {
  return await CustomRequest(
    "POST",
    "/study/verification",
    undefined,
    undefined,
    {
      email: username,
    }
  );
}
