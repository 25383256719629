import React, { useEffect, useState } from "react";
import "../../Main.css";
import { HandleUsers } from "./HandleUsers";
import { HandleAdmins } from "./HandleAdmins";
import { ViewData } from "./ViewData";
import { Settings } from "./Settings";
import Divider from "@mui/material/Divider";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";

export function Admin() {
  const [current_pressed, setCurrentPressed] = useState(-1);
  const [button_variants, setButtonVariants] = useState([
    "outlined",
    "outlined",
    "outlined",
    "outlined",
  ]);

  const theme = createTheme({
    palette: {
      neutral: {
        main: "#61a1ab",
        contrastText: "#fff",
      },
    },
  });

  useEffect(() => {
    let button_variants_new = ["outlined", "outlined", "outlined", "outlined"];
    if (current_pressed != -1) {
      button_variants_new[current_pressed] = "contained";
    }
    setButtonVariants(button_variants_new);
  }, [current_pressed]);

  function ButtonTemplate({ index, title }) {
    return (
      <ThemeProvider theme={theme}>
        <Button
          key={title}
          onClick={() => {
            current_pressed === index
              ? setCurrentPressed(-1)
              : setCurrentPressed(index);
          }}
          color="neutral"
          variant={button_variants[index]}
          sx={{
            width: "135px",
            height: "50px",
            lineHeight: "130%",
          }}
        >
          {title}
        </Button>
      </ThemeProvider>
    );
  }

  return (
    <>
      <div className="title-no-form">
        <ButtonTemplate index={0} title="HANTERA ANVÄNDARE" />
        <div className="between-admin-buttons"></div>
        <ButtonTemplate index={1} title="HANTERA ADMIN" />
      </div>
      <div className="title-no-form-second-row">
        <ButtonTemplate index={2} title="TRÄNINGSDATA" />
        <div className="between-admin-buttons"></div>
        <ButtonTemplate index={3} title="INSTÄLLNINGAR" />
      </div>
      {current_pressed != -1 && (
        <div className="divider-mui-padding-top">
          <Divider />
        </div>
      )}

      {current_pressed === 0 && <HandleUsers />}
      {current_pressed === 1 && <HandleAdmins />}
      {current_pressed === 2 && <ViewData />}
      {current_pressed === 3 && <Settings />}
    </>
  );
}
