import { CustomRequest } from "./Rest";

export async function GetUsers(jwt) {
  return await CustomRequest(
    "GET",
    "/study",
    undefined,
    undefined,
    undefined,
    jwt
  );
}

export async function AddUser(username, jwt) {
  return await CustomRequest(
    "POST",
    "/study",
    undefined,
    undefined,
    {
      email: username,
    },
    jwt
  );
}

export async function DeleteUser(username, jwt) {
  return await CustomRequest(
    "DELETE",
    "/study",
    undefined,
    undefined,
    {
      email: username,
    },
    jwt
  );
}

export async function RegisterUser(username) {
  return await CustomRequest("POST", "/register", undefined, undefined, {
    email: username,
  });
}

export async function GetAdmins(jwt) {
  return await CustomRequest(
    "GET",
    "/study/admin",
    undefined,
    undefined,
    undefined,
    jwt
  );
}

export async function AddAdmin(username, jwt) {
  return await CustomRequest(
    "POST",
    "/study/admin",
    undefined,
    undefined,
    {
      email: username,
    },
    jwt
  );
}

export async function DeleteAdmin(username, jwt) {
  return await CustomRequest(
    "DELETE",
    "/study/admin",
    undefined,
    undefined,
    {
      email: username,
    },
    jwt
  );
}

export async function GetDataOneUser(jwt, user) {
  return await CustomRequest(
    "GET",
    "/study/data/" + user,
    undefined,
    undefined,
    undefined,
    jwt
  );
}

export async function GetDataAllUsersInterval(jwt, start_date, end_date) {
  return await CustomRequest(
    "GET",
    "/study/data/" + start_date + "/" + end_date,
    undefined,
    undefined,
    undefined,
    jwt
  );
}

export async function GetAdminData(jwt) {
  return await CustomRequest(
    "GET",
    "/users",
    undefined,
    undefined,
    undefined,
    jwt
  );
}

export async function PatchAdminData(
  jwt,
  force_0,
  force_1,
  force_2,
  duration_0,
  duration_1,
  duration_2
) {
  return await CustomRequest(
    "PATCH",
    "/users",
    undefined,
    undefined,
    {
      pull_force_level_0: parseFloat(force_0),
      pull_force_level_1: parseFloat(force_1),
      pull_force_level_2: parseFloat(force_2),
      pull_duration_level_0: parseFloat(duration_0),
      pull_duration_level_1: parseFloat(duration_1),
      pull_duration_level_2: parseFloat(duration_2),
    },
    jwt
  );
}
