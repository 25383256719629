import React, { useEffect, useState } from "react";
import "../../Main.css";
import { useIQoroStore } from "../../Stores/General";
import { SubmitLogin } from "../../API/Authentication";
import { GetAdminData } from "../../API/Admin";
import {
  msg_unauthorized_admin_login,
  msg_enter_email_and_pass,
  errorHandling,
} from "../Util";
import TextField from "@mui/material/TextField";

export function AdminLogin() {
  const [
    setShowSpinner,
    setToasterMessage,
    setCurrentUser,
    setCurrentPage,
    token,
    setToken,
    setShowToaster,
    setDurationLevel0,
    setDurationLevel1,
    setDurationLevel2,
    setForceLevel0,
    setForceLevel1,
    setForceLevel2,
  ] = useIQoroStore((state) => [
    state.setShowSpinner,
    state.setToasterMessage,
    state.setCurrentUser,
    state.setCurrentPage,
    state.token,
    state.setToken,
    state.setShowToaster,
    state.setDurationLevel0,
    state.setDurationLevel1,
    state.setDurationLevel2,
    state.setForceLevel0,
    state.setForceLevel1,
    state.setForceLevel2,
  ]);

  const [login_successful, setLoginSuccessful] = useState(false);
  const [shrink_address, setShrinkAddress] = useState(true);

  function save_data_to_localstorage(input_id) {
    const input_val = document.getElementById(input_id).value;
    localStorage.setItem(input_id, input_val);
  }

  // useEffect(() => {
  //   if (document.getElementById("admin-login-entry").value.length > 0) {
  //     setShrinkAddress(true);
  //   } else if (document.getElementById("admin-login-entry").value.length == 0) {
  //     setShrinkAddress(false);
  //   }
  // });

  useEffect(() => {
    document
      .getElementById("admin-login-entry")
      .addEventListener("keyup", () => {
        save_data_to_localstorage("admin-login-entry");
      });

    document.getElementById("pwd-entry").addEventListener("keyup", () => {
      save_data_to_localstorage("pwd-entry");
    });

    if (localStorage["admin-login-entry"]) {
      document.getElementById("admin-login-entry").value =
        localStorage["admin-login-entry"];
    }

    if (localStorage["pwd-entry"]) {
      document.getElementById("pwd-entry").value = localStorage["pwd-entry"];
    }
  }, []);

  function onSubmitLogin(e) {
    setShowToaster(false);
    e.preventDefault();
    const username_challanger =
      document.getElementById("admin-login-entry").value;
    const password_challanger = document.getElementById("pwd-entry").value;

    if (username_challanger.length === 0 || password_challanger.length === 0) {
      setToasterMessage({
        msg: msg_enter_email_and_pass,
        type: "error",
      });
    } else {
      setShowSpinner(true);
      return SubmitLogin(username_challanger, password_challanger).then(
        (data) => {
          if (data.status === 202) {
            setToken(data.token);
            setCurrentUser(username_challanger);
            setLoginSuccessful(true);
          } else {
            const msg = data.message
              ? data.status + ": " + data.message
              : data.status;
            throw new Error(msg);
          }
        }
      );
    }
  }

  function onGetAdminData() {
    setShowToaster(false);
    setShowSpinner(true);
    return GetAdminData(token).then((data) => {
      if (data.status === 200) {
        setDurationLevel0(data.pull_duration_level_0);
        setDurationLevel1(data.pull_duration_level_1);
        setDurationLevel2(data.pull_duration_level_2);
        setForceLevel0(data.pull_force_level_0);
        setForceLevel1(data.pull_force_level_1);
        setForceLevel2(data.pull_force_level_2);
        setShowSpinner(false);
        setCurrentPage("admin");
      } else {
        const msg = data.message
          ? data.status + ": " + data.message
          : data.status;
        throw new Error(msg);
      }
    });
  }

  useEffect(() => {
    if (login_successful) {
      setLoginSuccessful(false);
      errorHandling(
        onGetAdminData,
        [],
        setToasterMessage,
        [
          { code: "401", text: msg_unauthorized_admin_login },
          { code: "404", text: msg_unauthorized_admin_login },
        ],
        setShowSpinner
      );
    }
  }, [login_successful]);

  return (
    <>
      <form
        className="login-form"
        id="admin-login-form"
        name="form"
        method="post"
        action=""
      >
        <fieldset>
          <legend className="login-form-header">ADMIN</legend>
          {/* <div className="login-form-body">
            <label htmlFor="epost">e-postadress</label> <br />
            <input
              className="login-form-input"
              type="email"
              name="epost"
              id="admin-login-entry"
            />
            <br />
            <br />
            <label htmlFor="pwd">kod</label> <br />
            <input
              className="login-form-input"
              type="text"
              name="pwd"
              id="pwd-entry"
            />
            <br />
          </div> */}
          <TextField
            id="admin-login-entry"
            label="e-postadress"
            size="small"
            sx={{ width: "250px", marginTop: "20px" }}
            inputProps={{ style: { fontSize: "14px" } }}
            InputLabelProps={{
              style: { fontSize: "14px" },
            }}
          />
          <br />
          <TextField
            id="pwd-entry"
            label="kod"
            size="small"
            sx={{ width: "250px", marginTop: "20px" }}
            inputProps={{ style: { fontSize: "14px" } }}
            InputLabelProps={{ style: { fontSize: "14px" } }}
          />
          <div className="login-form-button">
            <div
              className="button"
              onClick={(e) =>
                errorHandling(
                  onSubmitLogin,
                  [e],
                  setToasterMessage,
                  [
                    { code: "200", text: msg_unauthorized_admin_login },
                    { code: "401", text: msg_unauthorized_admin_login },
                    { code: "404", text: msg_unauthorized_admin_login },
                  ],
                  setShowSpinner
                )
              }
            >
              Logga in
            </div>
          </div>
        </fieldset>
      </form>
      <div className="login-as">
        <div>Logga in som</div>&nbsp;
        <div
          className="login-as-onclick"
          onClick={() => setCurrentPage("user-verify")}
        >
          användare
        </div>
      </div>
    </>
  );
}
