import React, { useState, useEffect } from "react";
import "../../Main.css";
import { useIQoroStore } from "../../Stores/General";
import TextField from "@mui/material/TextField";
import { PatchAdminData } from "../../API/Admin";
import { msg_unauthorized_operation, errorHandling } from "../Util";

export function Settings() {
  const box_width = 91;
  const [
    setToasterMessage,
    token,
    setShowSpinner,
    setShowToaster,
    duration_level0,
    duration_level1,
    duration_level2,
    force_level0,
    force_level1,
    force_level2,
    setDurationLevel0,
    setDurationLevel1,
    setDurationLevel2,
    setForceLevel0,
    setForceLevel1,
    setForceLevel2,
  ] = useIQoroStore((state) => [
    state.setToasterMessage,
    state.token,
    state.setShowSpinner,
    state.setShowToaster,
    state.duration_level0,
    state.duration_level1,
    state.duration_level2,
    state.force_level0,
    state.force_level1,
    state.force_level2,
    state.setDurationLevel0,
    state.setDurationLevel1,
    state.setDurationLevel2,
    state.setForceLevel0,
    state.setForceLevel1,
    state.setForceLevel2,
  ]);

  function saveSettings() {
    setShowSpinner(true);
    setShowToaster(false);
    return PatchAdminData(
      token,
      force_level0,
      force_level1,
      force_level2,
      duration_level0,
      duration_level1,
      duration_level2
    ).then((data) => {
      if (data.status === 200) {
        setShowSpinner(false);
      } else {
        const msg = data.message
          ? data.status + ": " + data.message
          : data.status;
        throw new Error(msg);
      }
    });
  }

  return (
    <div className="settings">
      <div className="settings-title">TRÄNINGSDATA</div>
      <br /> <br />
      <div className="flex space-between">
        <div className="font-size-12">DRAGNING, LÄNGD (s)</div>
        <TextField
          id="setting-pull-min"
          label="Nivå röd"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          sx={{ width: box_width }}
          value={duration_level0}
          onChange={(event) => {
            setDurationLevel0(event.target.value);
          }}
        />
      </div>
      <br />
      <div className="justify-content-end">
        <TextField
          id="filled-number"
          label="Nivå orange"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          sx={{ width: box_width }}
          value={duration_level1}
          onChange={(event) => {
            setDurationLevel1(event.target.value);
          }}
        />
      </div>
      <br />
      <div className="justify-content-end">
        <TextField
          id="filled-number"
          label="Nivå grön"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          sx={{ width: box_width }}
          value={duration_level2}
          onChange={(event) => {
            setDurationLevel2(event.target.value);
          }}
        />
      </div>
      <br />
      <br />
      <div className="divider-less" />
      <br />
      <br />
      <div className="flex space-between">
        <div className="font-size-12">DRAGNING, KRAFT (N)</div>
        <TextField
          id="setting-pull-min"
          label="Nivå röd"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          sx={{ width: box_width }}
          value={force_level0}
          onChange={(event) => {
            setForceLevel0(event.target.value);
          }}
        />
      </div>
      <br />
      <div className="justify-content-end">
        <TextField
          id="filled-number"
          label="Nivå orange"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          sx={{ width: box_width }}
          value={force_level1}
          onChange={(event) => {
            setForceLevel1(event.target.value);
          }}
        />
      </div>
      <br />
      <div className="justify-content-end">
        <TextField
          id="filled-number"
          label="Nivå grön"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          sx={{ width: box_width }}
          value={force_level2}
          onChange={(event) => {
            setForceLevel2(event.target.value);
          }}
        />
      </div>
      <div className="login-form-button">
        <div
          className="button"
          onClick={() =>
            errorHandling(
              saveSettings,
              [],
              setToasterMessage,
              [{ code: "403", text: msg_unauthorized_operation }],
              setShowSpinner,
              2
            )
          }
        >
          Spara
        </div>
      </div>
    </div>
  );
}
