export const baseUrl = "https://iqoro-app-container.azurewebsites.net";
// "https://iqoro-app-container-internal-test.azurewebsites.net";
// export const baseUrl = "http://localhost:8083"; //during development!!!

function objToQueryString(queryParameters) {
  return (
    "?" +
    Object.keys(queryParameters)
      .map(
        (key) => key + "=" + encodeURIComponent(queryParameters[key].toString())
      )
      .join("&")
  );
}

async function parseJSONorBLOB(response) {
  let headers = {};
  for (let pair of response.headers.entries()) headers[pair[0]] = pair[1];

  // 1. If an OK response is received without body (status 201): don't parse JSON
  // 2. If a NOK response is received, return Error.
  //    First check if this is a message containing json. If so, decode response with json.
  //    Else, create the error message as the HTTP response code + status text
  // 3. Else, decode it as json, and add the response status (e.g. 200, 202, 203 etc).

  //1.
  if (response.status === 201) {
    return "";
  }

  //2.
  if (!response.ok) {
    let errorMsg = response.status + ": " + response.statusText;
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
      const msg = await response.json();
      if (msg.error) {
        errorMsg = errorMsg + " (" + msg.error + ")";
      }
    }

    throw new Error(errorMsg);
  }

  //3
  const json = await response.json();
  return { ...json, status: response.status };
}

export function CustomRequest(
  method,
  relativeUrl,
  timeout = 8000,
  queryParameters,
  postData,
  token
) {
  if (!postData) postData = {};
  let queryString = "";
  if (queryParameters) queryString = objToQueryString(queryParameters);

  let headers = { "Content-Type": "application/json" };

  if (token) {
    headers = { ...headers, Authorization: `Bearer ${token}` };
  }

  const controller = new AbortController();
  const timer = setTimeout(() => controller.abort(), timeout);

  return new Promise((resolve, reject) => {
    let fetchOptions = {
      method,
      mode: "cors",
      headers,
      body: method === "GET" ? undefined : JSON.stringify(postData),
      signal: controller.signal,
    };
    fetch(baseUrl + relativeUrl + queryString, fetchOptions)
      .then((x) => {
        clearTimeout(timer);
        return parseJSONorBLOB(x);
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let error_msg = "";

        // Timeout from the AbortController object above
        if (error.name === "AbortError") {
          error_msg = "No response from server";
        }
        // Connection problem with server, not getting any response at all
        else if (
          error.name === "TypeError" &&
          error.message === "Failed to fetch"
        ) {
          error_msg = "Connection problem with server";
        }
        // All other errors are handled in parseJSONorBLOB
        else {
          error_msg = error.message;
        }
        reject(error_msg);
      });
  });
}
