import React, { useState, useEffect } from "react";
import "../../Main.css";
import { useIQoroStore } from "../../Stores/General";
import { GetUsers, AddUser, DeleteUser } from "../../API/Admin";
import {
  setFocus,
  set_cursor_at_end,
  onTab,
  trimContent,
  validateEmail,
  enter_email,
  RadioButtonFilter,
  msg_successful_operation,
  msg_unauthorized_operation,
  msg_not_part_of_study,
  msg_email_unknown,
  msg_no_users,
  errorHandling,
} from "../Util";
import WarningIcon from "@mui/icons-material/Warning";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import ButtonGroup from "@mui/material/ButtonGroup";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Divider from "@mui/material/Divider";

export function HandleUsers() {
  const [setToasterMessage, token, setShowSpinner, setShowToaster] =
    useIQoroStore((state) => [
      state.setToasterMessage,
      state.token,
      state.setShowSpinner,
      state.setShowToaster,
    ]);
  const [new_user_to_add, setNewUserToAdd] = useState(false);
  const [new_user_to_delete, setNewUserToDelete] = useState(false);
  const [nr_of_users_to_add, setNrOfUsersToAdd] = useState(0);
  const [nr_of_users_to_delete, setNrOfUsersToDelete] = useState(0);
  const [show_all_users, setShowAllUsers] = useState(false);
  const [users, setUsers] = useState(null);
  const [users_to_add, setUsersToAdd] = useState([]);
  const [users_to_delete, setUsersToDelete] = useState([]);
  const [show_add_users, setShowAddUsers] = useState(false);
  const [show_delete_users, setShowDeleteUsers] = useState(false);
  const [show_confirm_inactivation, setShowConfirmInactivation] =
    useState(false);
  const [invalid_emails, setInvalidEmails] = useState([]);
  const [show_users_switch, setShowUsersSwitch] = useState("all");
  const [button_variant_1, setButtonVariant1] = useState("outlined");
  const [button_variant_2, setButtonVariant2] = useState("outlined");
  const [button_variant_3, setButtonVariant3] = useState("outlined");

  useEffect(() => {
    if (new_user_to_add) {
      if (document.getElementById(`add-user-${nr_of_users_to_add - 1}`)) {
        document.getElementById("add-user-last").blur();
        setFocus(`add-user-${nr_of_users_to_add - 1}`);
        document.getElementById(
          `add-user-${nr_of_users_to_add - 1}`
        ).innerText = document.getElementById("add-user-last").innerText;
        document.getElementById("add-user-last").innerText = "";
        set_cursor_at_end(
          document.getElementById(`add-user-${nr_of_users_to_add - 1}`)
        );
        setNewUserToAdd(false);
      }
    } else if (new_user_to_delete) {
      if (document.getElementById(`delete-user-${nr_of_users_to_delete - 1}`)) {
        document.getElementById("delete-user-last").blur();
        setFocus(`delete-user-${nr_of_users_to_delete - 1}`);
        document.getElementById(
          `delete-user-${nr_of_users_to_delete - 1}`
        ).innerText = document.getElementById("delete-user-last").innerText;
        document.getElementById("delete-user-last").innerText = "";
        set_cursor_at_end(
          document.getElementById(`delete-user-${nr_of_users_to_delete - 1}`)
        );
        setNewUserToDelete(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [new_user_to_add, new_user_to_delete]);

  useEffect(() => {
    if (users) {
      setTimeout(() => {
        setShowAllUsers(true);
      }, 10);
    }
  }, [users]);

  function onGetUsersIfClosed() {
    if (!show_all_users) {
      setButtonVariant1("contained");
      errorHandling(
        onGetUsers,
        [],
        setToasterMessage,
        [{ code: "403", text: msg_unauthorized_operation }],
        setShowSpinner,
        2
      );
    } else {
      setShowAllUsers(false);
      setButtonVariant1("outlined");
    }
  }

  function onGetUsers() {
    setShowSpinner(true);
    setShowToaster(false);
    return GetUsers(token).then((data) => {
      if (data.status === 200) {
        setShowSpinner(false);
        let users_temp = [];
        for (const x in data) {
          x !== "status" && users_temp.push(data[x]);
        }
        setUsers(users_temp.sort());
      } else {
        const msg = data.message
          ? data.status + ": " + data.message
          : data.status;
        throw new Error(msg);
      }
    });
  }

  useEffect(() => {
    for (let i = 0; i < nr_of_users_to_add; i++) {
      let invalid = false;
      for (let j = 0; j < invalid_emails.length; j++) {
        if (
          document.getElementById("add-user-" + i).innerText ===
          invalid_emails[j]
        ) {
          document.getElementById("add-user-" + i).style.color = "red";
          invalid = true;
        } else {
          document.getElementById("add-user-" + i).style.color = "#333333";
        }
        if (invalid) {
          break;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invalid_emails]);

  function onAddUsers() {
    let invalid_emails_temp = [];
    for (let i = 0; i < nr_of_users_to_add; i++) {
      if (!validateEmail(document.getElementById("add-user-" + i).innerText)) {
        invalid_emails_temp.push(
          document.getElementById("add-user-" + i).innerText
        );
      }
    }
    setInvalidEmails(invalid_emails_temp);
    if (invalid_emails_temp.length > 0) {
      setToasterMessage({
        msg: msg_email_unknown,
        type: "error",
      });
    } else {
      setShowSpinner(true);
      setShowToaster(false);
      for (let i = 0; i < nr_of_users_to_add; i++) {
        errorHandling(
          onAddUser,
          [i],
          setToasterMessage,
          [{ code: "403", text: msg_unauthorized_operation }],
          setShowSpinner
        );
      }
    }
  }

  function onAddUser(index) {
    return AddUser(
      document.getElementById("add-user-" + index).innerText,
      token
    ).then((data) => {
      if (data.status === 200) {
        if (index === nr_of_users_to_add - 1) {
          setShowSpinner(false);
          setToasterMessage({
            msg: msg_successful_operation,
            type: "success",
          });
          setNrOfUsersToAdd(0);
          setShowAllUsers(false);
        }
      } else {
        const msg = data.message
          ? data.status + ": " + data.message
          : data.status;
        throw new Error(msg);
      }
    });
  }

  function onDeleteUsers() {
    setShowSpinner(true);
    setShowToaster(false);
    setShowConfirmInactivation(false);
    for (let i = 0; i < nr_of_users_to_delete; i++) {
      errorHandling(
        onDeleteUser,
        [i],
        setToasterMessage,
        [
          { code: "403", text: msg_unauthorized_operation },
          {
            code: "404",
            text:
              document.getElementById("delete-user-" + i).innerText +
              " " +
              msg_not_part_of_study,
          },
        ],
        setShowSpinner
      );
    }
  }

  function onDeleteUser(index) {
    return DeleteUser(
      document.getElementById("delete-user-" + index).innerText,
      token
    ).then((data) => {
      if (data.status === 200) {
        if (index === nr_of_users_to_delete - 1) {
          setShowSpinner(false);
          setToasterMessage({
            msg: msg_successful_operation,
            type: "success",
          });
          setNrOfUsersToDelete(0);
          setShowAllUsers(false);
        }
      } else {
        const msg = data.message
          ? data.status + ": " + data.message
          : data.status;
        throw new Error(msg);
      }
    });
  }

  function onChangeUsersLast() {
    if (document.getElementById("add-user-last").innerText.length > 0) {
      setNrOfUsersToAdd(nr_of_users_to_add + 1);
      setNewUserToAdd(true);
    }
  }

  function onChangeDeleteUsersLast() {
    if (document.getElementById("delete-user-last").innerText.length > 0) {
      setNrOfUsersToDelete(nr_of_users_to_delete + 1);
      setNewUserToDelete(true);
    }
  }

  function onBlurUsers(id_base, ix) {
    if (document.getElementById(id_base + ix).innerText.length === 0) {
      let nr_of_users = 0;
      if (id_base.substr(0, 3) === "add") {
        setNrOfUsersToAdd(nr_of_users_to_add - 1);
        nr_of_users = nr_of_users_to_add;
      } else {
        setNrOfUsersToDelete(nr_of_users_to_delete - 1);
        nr_of_users = nr_of_users_to_delete;
      }
      for (let i = ix; i < nr_of_users - 1; i++) {
        document.getElementById(id_base.concat(i)).innerText =
          document.getElementById(id_base.concat(i + 1)).innerText;
      }
    }
  }

  function saveUsersToAdd() {
    let users_to_add_temp = [];
    for (let i = 0; i < nr_of_users_to_add; i++) {
      users_to_add_temp.push(
        document.getElementById("add-user-" + i).innerText
      );
    }
    setUsersToAdd(users_to_add_temp);
  }

  function saveUsersToDelete() {
    let users_to_delete_temp = [];
    for (let i = 0; i < nr_of_users_to_delete; i++) {
      users_to_delete_temp.push(
        document.getElementById("delete-user-" + i).innerText
      );
    }
    setUsersToDelete(users_to_delete_temp);
  }

  function ConfirmInactivation() {
    return (
      <div className="confirm-box">
        <div className="confirm-box-top">
          <WarningIcon />
          <div className="confirm-box-text">
            Vill du verkligen inaktivera dessa användare?
          </div>
        </div>
        <div className="confirm-box-buttons">
          <div className="confirm-box-button">
            <div className="button" onClick={onDeleteUsers}>
              Ja
            </div>
          </div>
          <div className="confirm-box-button">
            <div
              className="button"
              onClick={() => setShowConfirmInactivation(false)}
            >
              Nej
            </div>
          </div>
        </div>
      </div>
    );
  }

  const theme = createTheme({
    palette: {
      neutral: {
        main: "#646464",
        contrastText: "#fff",
      },
    },
  });

  function showAddUsers() {
    if (show_add_users) {
      saveUsersToAdd();
      setButtonVariant2("outlined");
    } else {
      setButtonVariant2("contained");
    }
    if (show_delete_users) {
      saveUsersToDelete();
      setButtonVariant3("outlined");
    }
    setShowAddUsers(!show_add_users);
    setShowDeleteUsers(false);
  }

  function showDeleteUsers() {
    if (show_delete_users) {
      saveUsersToDelete();
      setButtonVariant3("outlined");
    } else {
      setButtonVariant3("contained");
    }
    if (show_add_users) {
      saveUsersToAdd();
      setButtonVariant2("outlined");
    }
    setShowDeleteUsers(!show_delete_users);
    setShowAddUsers(false);
  }

  const buttons1 = [
    <ThemeProvider theme={theme}>
      <Button
        key="one"
        onClick={onGetUsersIfClosed}
        color="neutral"
        variant={button_variant_1}
      >
        Lista
      </Button>
    </ThemeProvider>,
  ];
  const buttons2 = [
    <ThemeProvider theme={theme}>
      <Button
        key="two"
        onClick={showAddUsers}
        color="neutral"
        variant={button_variant_2}
      >
        Lägg till/aktivera
      </Button>
    </ThemeProvider>,
    <ThemeProvider theme={theme}>
      <Button
        key="three"
        onClick={showDeleteUsers}
        color="neutral"
        variant={button_variant_3}
      >
        Inaktivera
      </Button>
    </ThemeProvider>,
  ];

  useEffect(() => {
    if (show_all_users && users && users.length === 0) {
      setToasterMessage({
        msg: msg_no_users,
        type: "info",
      });
    }
  }, [show_all_users, users]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          "& > *": {
            m: 1,
          },
        }}
      >
        <ButtonGroup size="small" aria-label="small button group">
          {buttons1}
        </ButtonGroup>
      </Box>
      {users && users.length > 0 && (
        <div
          className={
            show_all_users
              ? "all-users-list-outest max-height-all-users"
              : "all-users-list-outest max-height-0"
          }
          style={{
            "--nr-of-users": users.length + 1,
          }}
        >
          {
            <RadioButtonFilter
              button_list={[
                { var: "all", display: "Alla" },
                { var: "active", display: "Aktiva" },
                { var: "inactive", display: "Inaktiva" },
              ]}
              setter_function={(a) => {
                setShowUsersSwitch(a);
              }}
              state_var={show_users_switch}
            />
          }
          <div className="all-users-list-outer">
            <div className="all-users-list cursor-text">
              <div className="table-header-active">AKTIV</div>
              <div className="table-header-user">ANVÄNDARE</div>
            </div>
            {users.map((user, ix) => {
              if (
                (show_users_switch !== "active" && !user.active) ||
                (show_users_switch !== "inactive" && user.active)
              )
                return (
                  <div
                    key={`user-${ix}`}
                    className="all-users-list cursor-text"
                  >
                    <div className="table-body-active">
                      {user.active ? (
                        <div className="led led-green" />
                      ) : (
                        <div className="led led-red" />
                      )}
                    </div>
                    {user.email}
                  </div>
                );
            })}
          </div>
        </div>
      )}
      <div className="divider-mui">
        <Divider />
      </div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          "& > *": {
            m: 1,
          },
        }}
      >
        <ButtonGroup size="small" aria-label="small button group">
          {buttons2}
        </ButtonGroup>
      </Box>
      {show_add_users && (
        <div className="add-users-list-outer">
          <div className="one-px-height" />
          {[...Array(nr_of_users_to_add).keys()].map((nr) => (
            <div
              id={`add-user-${nr}`}
              key={`add-user-${nr}`}
              className="add-users-list no-border-bottom"
              contentEditable="true"
              suppressContentEditableWarning={true}
              onClick={() => setFocus(`add-user-${nr}`)}
              onKeyDown={(e) => onTab(e, "add-user-", nr, nr_of_users_to_add)}
              spellCheck="false"
              onBlur={() => {
                trimContent("add-user-", nr);
                onBlurUsers("add-user-", nr);
              }}
            >
              {users_to_add[nr]}
            </div>
          ))}
          <div
            id="add-user-last"
            className="add-users-list placeholder-text"
            contentEditable="true"
            suppressContentEditableWarning={true}
            onClick={() => setFocus("add-user-last")}
            onKeyUp={() => onChangeUsersLast()}
            onKeyDown={(e) => onTab(e, "add-user-", "last", nr_of_users_to_add)}
            spellCheck="false"
            placeholder={enter_email}
          />
          <div className="admin-form-button">
            <div
              className="button"
              onClick={() => nr_of_users_to_add > 0 && onAddUsers()}
            >
              Lägg till/aktivera
            </div>
          </div>
        </div>
      )}

      {show_delete_users && (
        <div className="add-users-list-outer">
          <div className="one-px-height" />
          {[...Array(nr_of_users_to_delete).keys()].map((nr) => (
            <div
              id={`delete-user-${nr}`}
              key={`delete-user-${nr}`}
              className="add-users-list no-border-bottom"
              contentEditable="true"
              suppressContentEditableWarning={true}
              onClick={() => setFocus(`delete-user-${nr}`)}
              onKeyDown={(e) =>
                onTab(e, "delete-user-", nr, nr_of_users_to_delete)
              }
              spellCheck="false"
              onBlur={() => {
                trimContent("delete-user-", nr);
                onBlurUsers("delete-user-", nr);
              }}
            >
              {users_to_delete[nr]}
            </div>
          ))}
          <div
            id="delete-user-last"
            className="add-users-list placeholder-text"
            contentEditable="true"
            suppressContentEditableWarning={true}
            onClick={() => setFocus("delete-user-last")}
            onKeyUp={() => onChangeDeleteUsersLast()}
            onKeyDown={(e) =>
              onTab(e, "delete-user-", "last", nr_of_users_to_delete)
            }
            spellCheck="false"
            placeholder={enter_email}
          />
          <div className="admin-form-button">
            <div
              className="button"
              onClick={() =>
                nr_of_users_to_delete > 0 && setShowConfirmInactivation(true)
              }
            >
              Inaktivera
            </div>
          </div>
        </div>
      )}
      {show_confirm_inactivation && <ConfirmInactivation />}
      <div
        className={
          show_confirm_inactivation
            ? "blur-background blur-background-intro"
            : "blur-background blur-background-outro"
        }
      />
    </>
  );
}
