import React, { useEffect } from "react";
import "../../Main.css";
import { SubmitVerify } from "../../API/Authentication";
import { useIQoroStore } from "../../Stores/General";
import {
  msg_this_user_not_part_of_study,
  msg_enter_email,
  errorHandling,
} from "../Util";
import TextField from "@mui/material/TextField";

export function UserVerify() {
  const [
    setShowSpinner,
    setToasterMessage,
    setCurrentUser,
    setCurrentPage,
    setLastSync,
    setShowToaster,
  ] = useIQoroStore((state) => [
    state.setShowSpinner,
    state.setToasterMessage,
    state.setCurrentUser,
    state.setCurrentPage,
    state.setLastSync,
    state.setShowToaster,
  ]);

  function save_data_to_localstorage(input_id) {
    const input_val = document.getElementById(input_id).value;
    localStorage.setItem(input_id, input_val);
  }

  useEffect(() => {
    document.getElementById("username-entry").addEventListener("keyup", () => {
      save_data_to_localstorage("username-entry");
    });

    if (localStorage["username-entry"]) {
      document.getElementById("username-entry").value =
        localStorage["username-entry"];
    }
  }, []);

  function onSubmitVerify(e) {
    e.preventDefault();
    setShowSpinner(true);
    setShowToaster(false);
    const username_challanger = document.getElementById("username-entry").value;
    if (username_challanger.length === 0) {
      setToasterMessage({
        msg: msg_enter_email,
        type: "error",
      });
    } else {
      return SubmitVerify(username_challanger).then((data) => {
        if (data.status === 200) {
          setShowSpinner(false);
          setCurrentUser(username_challanger);
          setCurrentPage("user");
          setLastSync(new Date(data.lastSync));
        } else {
          const msg = data.message
            ? data.status + ": " + data.message
            : data.status;
          throw new Error(msg);
        }
      });
    }
  }

  return (
    <>
      <form
        className="login-form"
        id="user-form"
        name="form"
        method="post"
        action=""
      >
        <fieldset>
          <legend className="login-form-header">ANVÄNDARE</legend>
          {/* <div className="login-form-body">
            <label htmlFor="epost">e-postadress</label> <br />
            <input
              className="login-form-input"
              type="email"
              name="epost"
              id="username-entry"
            />
            <br />
          </div> */}
          <TextField
            id="username-entry"
            label="e-postadress"
            size="small"
            sx={{ width: "250px", marginTop: "20px" }}
            inputProps={{ style: { fontSize: "14px" } }}
            InputLabelProps={{ style: { fontSize: "14px" } }}
          />

          <div className="login-form-button">
            <div
              className="button"
              onClick={(e) =>
                errorHandling(
                  onSubmitVerify,
                  [e],
                  setToasterMessage,
                  [{ code: "404", text: msg_this_user_not_part_of_study }],
                  setShowSpinner
                )
              }
            >
              Logga in
            </div>
          </div>
        </fieldset>
      </form>
      <div className="login-as">
        <div>Logga in som</div>&nbsp;
        <div
          className="login-as-onclick"
          onClick={() => setCurrentPage("admin-login")}
        >
          admin
        </div>
      </div>
    </>
  );
}
