import React, { useState, useEffect } from "react";
import "../../Main.css";
import { useIQoroStore } from "../../Stores/General";
import dayjs from "dayjs";
import { GetDataOneUser, GetDataAllUsersInterval } from "../../API/Admin";
import {
  date_formatter,
  time_formatter,
  date_time_formatter,
  check_if_event_within_elements,
  setHoursRegardlessSummerTime,
  RadioButtonFilter,
  msg_unauthorized_operation,
  decoder_force_sequence,
  msg_user_unknown,
  msg_no_data_for_period,
  errorHandling,
  decoder_iqoro_id,
  tempComp,
} from "../Util";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { v4 as uuidv4 } from "uuid";
import TuneIcon from "@mui/icons-material/Tune";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const verdict_red = -1;
const verdict_orange = 0;
const verdict_green = 1;
const max_sessions_per_day = 3;

function FilterUsers() {
  const [filter_users, setFilterUsers] = useIQoroStore((state) => [
    state.filter_users,
    state.setFilterUsers,
  ]);
  const [show_filter, setShowFilter] = useState(false);
  return (
    <>
      <div className="filter-outer">
        <div className="filter" onClick={() => setShowFilter(!show_filter)}>
          <TuneIcon />
        </div>
      </div>
      <div
        className={
          show_filter
            ? "grid-template-rows-initial expand-grid-template-rows"
            : "grid-template-rows-initial"
        }
      >
        <div>
          <RadioButtonFilter
            button_list={[
              { var: "all", display: "Alla" },
              { var: verdict_green.toString(), display: "Grön" },
              { var: verdict_orange.toString(), display: "Orange" },
              { var: verdict_red.toString(), display: "Röd" },
            ]}
            setter_function={(a) => setFilterUsers(a)}
            state_var={filter_users}
          />
        </div>
      </div>
    </>
  );
}

export function ViewData() {
  const [
    setToasterMessage,
    token,
    setShowSpinner,
    show_spinner,
    start_date,
    end_date,
    filter_users,
    setFilterUsers,
    setShowToaster,
    duration_level0,
    duration_level1,
    duration_level2,
    force_level0,
    force_level1,
    force_level2,
  ] = useIQoroStore((state) => [
    state.setToasterMessage,
    state.token,
    state.setShowSpinner,
    state.show_spinner,
    state.start_date,
    state.end_date,
    state.filter_users,
    state.setFilterUsers,
    state.setShowToaster,
    state.duration_level0,
    state.duration_level1,
    state.duration_level2,
    state.force_level0,
    state.force_level1,
    state.force_level2,
  ]);

  const [training_report, setTrainingReport] = useState(new Map());
  const [current_searched_start_date, setCurrentSearchedStartDate] = useState(
    new Date()
  );
  const [current_searched_end_date, setCurrentSearchedEndDate] = useState(
    new Date()
  );
  const [user_data_available_for_period, setUserDataAvailableForPeriod] =
    useState(false);

  useEffect(() => {
    setFilterUsers("all");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function Rulez(iqoro_id, user, training_list, first_training_day) {
    let reports = [];

    training_list.forEach((training) => {
      let start_time_session = new Date(training.start_datetime);
      if (
        reports.length === 0 ||
        (reports.length > 0 &&
          date_formatter(new Date(reports[reports.length - 1].date)) !==
            date_formatter(new Date(start_time_session)))
      ) {
        reports.push({
          date: date_formatter(new Date(start_time_session)),
          verdict: NaN,
          sessions: [],
          day_nr:
            (setHoursRegardlessSummerTime(
              new Date(start_time_session)
            ).getTime() -
              setHoursRegardlessSummerTime(
                new Date(first_training_day)
              ).getTime()) /
              (1000 * 60 * 60 * 24) +
            1,
        });
      }
      let sample_rate = training.sample_rate;
      try {
        let force_sequence = tempComp(training.force_sequence);

        // Create array of timestamps for when each pull is started and ended, i.e.
        // when force sequence exceeds & descends of a certain level (force_level0).
        // If this period also is a certain minimum required time (duration_level0),
        // it's qualified as a pull
        let start_stop = [];
        for (let x = 0; x < force_sequence.length; x++) {
          if (
            x > 0 &&
            force_sequence[x] > force_level0 &&
            force_sequence[x - 1] <= force_level0
          ) {
            start_stop.push(x);
          } else if (
            x > 0 &&
            force_sequence[x] < force_level0 &&
            force_sequence[x - 1] >= force_level0
          ) {
            if (
              (x - start_stop[start_stop.length - 1]) / sample_rate >=
              duration_level0
            ) {
              start_stop.push(x);
            } else {
              start_stop && start_stop.pop();
            }
          }
        }

        if (start_stop.length % 2 !== 0) {
          throw new Error(
            "Ej avkodningsbar: Olika antal start och stopp för dragningar"
          );
        }

        let pull_reports = [];
        for (let j = 0; j < start_stop.length; j += 2) {
          let pull_report = {
            start_time_pull: new Date(start_time_session),
            verdict: verdict_green,
            max_force: NaN,
            average_force: NaN,
            duration: NaN,
          };

          // Start time
          pull_report.start_time_pull.setSeconds(
            start_time_session.getSeconds() + start_stop[j] / sample_rate
          );
          pull_report.start_time_pull = time_formatter(
            pull_report.start_time_pull
          );

          // Max force
          pull_report.max_force = Math.max(
            ...force_sequence.slice(start_stop[j], start_stop[j + 1])
          );

          // Average force
          pull_report.average_force = (
            force_sequence
              .slice(start_stop[j], start_stop[j + 1])
              .reduce((partial, a) => Number(partial) + Number(a), 0) /
            (start_stop[j + 1] - start_stop[j])
          ).toFixed(1);

          // Duration
          pull_report.duration =
            (start_stop[j + 1] - start_stop[j]) / sample_rate;

          // Verdict
          if (
            pull_report.duration < duration_level1 ||
            pull_report.max_force < force_level1
          ) {
            pull_report.verdict = verdict_red;
          } else if (
            pull_report.duration < duration_level2 ||
            pull_report.max_force < force_level2
          ) {
            pull_report.verdict = verdict_orange;
          }
          pull_reports.push(pull_report);
        }

        reports[reports.length - 1].sessions.push({
          verdict: Math.min(...pull_reports.map((pull) => pull.verdict)),
          pull_reports,
        });
      } catch (error) {
        let pull_reports = [
          {
            start_time_pull: time_formatter(new Date(start_time_session)),
            verdict: verdict_red,
            max_force: "X",
            average_force: "X",
            duration: "X",
          },
        ];
        reports[reports.length - 1].sessions.push({
          verdict: Math.min(...pull_reports.map((pull) => pull.verdict)),
          pull_reports,
        });
      }
    });

    for (let d = 0; d < reports.length; d++) {
      reports[d].verdict = Math.min(
        ...reports[d].sessions.map((session) => session.verdict)
      );
    }
    setTrainingReport(training_report.set(user, { iqoro_id, reports }));
  }
  /*
  DATA REPRESENTATION

  report
    |
    ---day-report
    ---day-report
      ..
    ---day-report
          |
            --- date = ...
                verdict = ...
                day_nr = ...
                sessions
                  |
                    --- verdict = ...
                    --- pull_reports 
                              |
                              --- start_time_pull = ...
                              --- verdict = ...
                              --- max_force = ...
                              --- average_force = ...
                              --- duration = ...
*/

  function DetailedTrainingDay({ day, last_day }) {
    const [show_day, setShowDay] = useState(false);
    return (
      <>
        <div
          className="cursor-pointer all-users-list"
          onClick={() => setShowDay(!show_day)}
        >
          {[...Array(max_sessions_per_day).keys()].map((session_nr) => (
            <div key={uuidv4()} className="table-body-active-sessions">
              {day.sessions.length < session_nr + 1 ? (
                <div />
              ) : day.sessions[session_nr].verdict === verdict_green ? (
                <div className="led-2 led-green" />
              ) : day.sessions[session_nr].verdict === verdict_orange ? (
                <div className="led-2 led-orange" />
              ) : (
                <div className="led-2 led-red" />
              )}
            </div>
          ))}
          <div className="table-body-date"> {day.date}</div>
          <div className="table-body-day-nr"> {day.day_nr}</div>
        </div>
        <div
          className={
            show_day
              ? "grid-template-rows-initial expand-grid-template-rows"
              : "grid-template-rows-initial"
          }
        >
          <div>
            <div
              className={
                last_day
                  ? "training-day rounded-bottom-corners"
                  : "training-day"
              }
            >
              <>
                <div className="all-users-list">
                  <div className="table-header-time">TID</div>
                  <div className="table-header-result2">RESULTAT</div>
                  <div className="table-header-duration">LÄNGD</div>
                  <div className="table-header-duration">MAX</div>
                  <div className="table-header-duration">MEDEL</div>
                </div>
                {day.sessions.map((session_nr) => {
                  return (
                    <div key={uuidv4()}>
                      {session_nr.pull_reports.map((pull) => (
                        <div key={uuidv4()} className="flex">
                          <div className="table-body-time">
                            {pull.start_time_pull}
                          </div>
                          {pull.verdict === verdict_green ? (
                            <div className="led-3 led-green" />
                          ) : pull.verdict === verdict_orange ? (
                            <div className="led-3 led-orange" />
                          ) : (
                            <div className="led-3 led-red" />
                          )}
                          <div className="table-body-duration">
                            {pull.duration}
                          </div>
                          <div className="table-body-max-force">
                            {pull.max_force}
                          </div>
                          <div className="table-body-average-force">
                            {pull.average_force}
                          </div>
                        </div>
                      ))}
                      <br />
                    </div>
                  );
                })}
              </>
            </div>
          </div>
        </div>
      </>
    );
  }

  function DetailedTrainingWeek({ user }) {
    return (
      <div className="detailed-training">
        <>
          <div className="all-users-list">
            <div className="table-header-result">RESULTAT</div>
            <div className="table-header-date">DATUM</div>
            <div className="table-header-day-nr">DAG NR</div>
          </div>
          {training_report.get(user).reports.map((one_day, j) => (
            <DetailedTrainingDay
              day={one_day}
              last_day={training_report.get(user).reports.length === j + 1}
              key={uuidv4()}
            />
          ))}
        </>
      </div>
    );
  }

  function DetailedTrainingUser({ user, id }) {
    const [show_week, setShowWeek] = useState(false);
    return (
      <>
        {training_report.get(user).reports.length > 0 &&
          (filter_users === "all" ||
            Number(filter_users) ===
              Math.min(
                ...training_report.get(user).reports.map((date) => date.verdict)
              )) && (
            <>
              <div className="all-users-list space-between">
                <div
                  className="flex cursor-pointer"
                  onClick={() => setShowWeek(!show_week)}
                >
                  <div className="table-body-active-training-data">
                    {Math.min(
                      ...training_report
                        .get(user)
                        .reports.map((date) => date.verdict)
                    ) === verdict_green ? (
                      <div className="led led-green" />
                    ) : Math.min(
                        ...training_report
                          .get(user)
                          .reports.map((date) => date.verdict)
                      ) === verdict_orange ? (
                      <div className="led led-orange" />
                    ) : (
                      <div className="led led-red" />
                    )}
                  </div>
                  <div className="table-body-user-id">{id}</div>
                </div>
                <div
                  className="download"
                  onClick={() =>
                    errorHandling(
                      getDataOneUser,
                      [user, id],
                      setToasterMessage,
                      [
                        { code: "403", text: msg_unauthorized_operation },
                        { code: "404", text: msg_user_unknown },
                      ],
                      setShowSpinner,
                      2
                    )
                  }
                >
                  <FileDownloadOutlinedIcon />
                </div>
              </div>

              <div
                className={
                  show_week
                    ? "grid-template-rows-initial expand-grid-template-rows"
                    : "grid-template-rows-initial"
                }
              >
                <div>
                  <DetailedTrainingWeek user={user} key={uuidv4()} />
                </div>
              </div>
            </>
          )}
      </>
    );
  }

  function getDataAllUsers() {
    setShowSpinner(true);
    setShowToaster(false);
    setFilterUsers("all");
    return GetDataAllUsersInterval(
      token,
      new Date(start_date).toISOString(),
      new Date(end_date).toISOString()
    ).then((data) => {
      if (data.status === 200) {
        setCurrentSearchedStartDate(new Date(start_date));
        setCurrentSearchedEndDate(new Date(end_date));
        let all_users_data = [];

        for (const i in data) {
          i !== "status" && all_users_data.push(data[i]);
        }
        if (
          all_users_data.length === 0 ||
          all_users_data.every((user_data) => user_data.data.length === 0)
        ) {
          setUserDataAvailableForPeriod(false);
          setToasterMessage({
            msg: msg_no_data_for_period,
            type: "info",
          });
        } else {
          setUserDataAvailableForPeriod(true);
          all_users_data.forEach((user_data) => {
            let decoded_training_data_list = [];
            let iqoro_id = "";
            user_data.data.forEach((encoded_training_data) => {
              const { sample_rate, force_sequence } = decoder_force_sequence(
                encoded_training_data.data
              );

              iqoro_id = decoder_iqoro_id(encoded_training_data.data);

              decoded_training_data_list.push({
                start_datetime: encoded_training_data.date,
                sample_rate,
                force_sequence,
              });
            });

            decoded_training_data_list.sort((a, b) => {
              return new Date(a.start_datetime) - new Date(b.start_datetime);
            });
            Rulez(
              iqoro_id,
              user_data.email,
              decoded_training_data_list,
              user_data.firstSync
            );
          });
        }
        setShowSpinner(false);
      } else {
        throw new Error(data.status + ": " + data.message);
      }
    });
  }

  function getDataOneUser(user, id) {
    setShowSpinner(true);
    setShowToaster(false);
    const csv_delimiter = ";";
    return GetDataOneUser(token, user).then((data) => {
      if (data.status === 200) {
        let encoded_training_data_list = [];
        for (const x in data) {
          x !== "status" && encoded_training_data_list.push(data[x]);
        }
        let decoded_training_data_list = [];
        for (const i in encoded_training_data_list) {
          decoded_training_data_list.push({
            ...decoder_force_sequence(encoded_training_data_list[i].data),
            start_datetime: date_time_formatter(
              new Date(encoded_training_data_list[i].date)
            ),
          });
        }

        decoded_training_data_list.sort((a, b) => {
          return new Date(a.start_datetime) - new Date(b.start_datetime);
        });

        let decoded_training_data_csv_file =
          "Time;Sample Rate [Hz];Pull Force Sequence [N]\n";

        for (const i in decoded_training_data_list) {
          let force_sequence = "";
          try {
            force_sequence = tempComp(
              decoded_training_data_list[i].force_sequence
            );
          } catch (error) {
            force_sequence = error;
          }
          decoded_training_data_csv_file +=
            decoded_training_data_list[i].start_datetime +
            csv_delimiter +
            decoded_training_data_list[i].sample_rate +
            csv_delimiter +
            force_sequence +
            "\n";
        }

        download(id + ".csv", decoded_training_data_csv_file);
        setShowSpinner(false);
      } else {
        const msg = data.message
          ? data.status + ": " + data.message
          : data.status;
        throw new Error(msg);
      }
    });
  }

  function BasicPopover() {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
      <div className="training-data-info">
        <InfoOutlinedIcon
          className="training-data-info-button"
          aria-describedby={id}
          onClick={handleClick}
        />
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          sx={{ maxWidth: "95%" }}
        >
          <Typography sx={{ p: 2 }}>
            <div className="bold">VISUALISERING</div>
            <ol>
              <li>
                Veckovy. Resultatet av alla träningsdagar den veckan för alla
                användare som har träningsdata inrapporterad. Resultatet tas
                fram genom att välja det sämsta resultatet (röd, orange, grön)
                från summeringen av dag-för-dag (nästa nivå). Här kan man även
                ladda ner all träningsdata för denna användare.
              </li>
              <li>
                Dag-för-dag-vy. Dit kommer man genom att klicka på en användare
                i veckovyn. Detta är resultatet av alla dragningar för
                respektive dag för den användaren. Resultatet tas fram genom att
                välja det sämsta resultatet (röd, orange, grön) från
                redovisningen av alla dragningar under denna dag (nästa nivå).
                Här visas även vilken dag i träningen (löpnummer) detta är.
              </li>
              <li>
                Detaljerad dagvy. Dit kommer man om man klickar på en dag i
                dag-för-dag-vyn. Här redovisas klockslag, varaktighet, max- och
                medelkraft för varje dragning samt en bedömning av dragningen
                (se nedan hur den bedömningen görs). Om någon beräkning (som t
                ex temperaturkompenering) skulle misslyckas, visas symbolen X
                för storheterna varaktighet, max- och medelkraft.
              </li>
            </ol>
            <div className="bold">BEDÖMNING</div>
            Resultatet av träningsdata bedöms utifrån två storheter: kraft och
            varaktighet. Det finns tre nivåer utav tröskelvärden respektive:
            <ol>
              <li>
                För att överhuvudtaget klassas som en dragning. Mellan denna och
                nästa nivå klassas det som rött.
              </li>
              <li>
                Halvbra nivå. Mellan denna och nästa nivå klassas det som
                orange.
              </li>
              <li>Godkänd nivå. Allt över denna nivå klassas som grönt.</li>
            </ol>
            Det sammansatta resultatet för en dragning tas fram genom att välja
            det sämsta resultatet (röd, orange, grön) utav kraft och tid.
          </Typography>
        </Popover>
      </div>
    );
  }

  return (
    <>
      <TimePeriod />
      <div className="get-training-form-button">
        <div
          className="button"
          onClick={() =>
            errorHandling(
              getDataAllUsers,
              [],
              setToasterMessage,
              [{ code: "403", text: msg_unauthorized_operation }],
              setShowSpinner,
              2
            )
          }
        >
          Hämta data
        </div>
      </div>
      {!show_spinner && user_data_available_for_period && (
        <>
          <BasicPopover />
          <FilterUsers />
          <div className="all-users-list-outest">
            <div className="all-users-list-outer padding-bottom-5px">
              <div className="searched-period-outer">
                <div className="searched-period">
                  {date_formatter(current_searched_start_date)}
                  &nbsp;&nbsp;-&nbsp;&nbsp;
                  {date_formatter(current_searched_end_date)}
                </div>
              </div>
              <div className="divider-less-padding" />

              <div className="all-users-list">
                <div className="table-header-active">RESULTAT</div>
                <div className="table-header-user">ANVÄNDARE</div>
              </div>
              {[...training_report.keys()].map((user) => (
                <DetailedTrainingUser
                  user={user}
                  id={training_report.get(user).iqoro_id}
                  key={uuidv4()}
                />
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
}

function TimePeriod() {
  const [start_date, setStartDate, end_date, setEndDate] = useIQoroStore(
    (state) => [
      state.start_date,
      state.setStartDate,
      state.end_date,
      state.setEndDate,
    ]
  );
  const [selected_calendar_date, setSelectedCalendarDate] = useState(
    dayjs(new Date())
  );
  const [show_calendar, setShowCalendar] = useState(false);
  const [left_arrow_clickable, setLeftArrowClickable] = useState(true);
  const [right_arrow_clickable, setRightArrowClickable] = useState(true);
  const start_of_study__string = "2023-04-03";
  const start_of_study = new Date(start_of_study__string);
  const start_of_study__dayjs = dayjs(start_of_study__string);
  const today = new Date();
  const end_of_study__dayjs = dayjs(new Date());

  useEffect(() => {
    const monday = 1; // According to Date() object
    const temp_date = new Date(selected_calendar_date.$d);
    const offset_from_monday = (temp_date.getDay() - monday + 7) % 7;
    const selected_date = temp_date.getDate();

    const start_time = new Date(
      temp_date.setDate(selected_date - offset_from_monday)
    );
    start_time.setHours(0, 0, 0, 0);

    const end_time_temp = new Date(start_time);
    end_time_temp.setDate(start_time.getDate() + 7);
    end_time_temp.setMilliseconds(-1);
    const end_time = new Date(end_time_temp);

    setStartDate(start_time);

    setEndDate(end_time);

    if (
      start_time.getTime() <= start_of_study.getTime() &&
      end_time.getTime() >= start_of_study.getTime()
    ) {
      setLeftArrowClickable(false);
    } else {
      setLeftArrowClickable(true);
    }
    if (
      start_time.getTime() <= today.getTime() &&
      end_time.getTime() >= today.getTime()
    ) {
      setRightArrowClickable(false);
    } else {
      setRightArrowClickable(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected_calendar_date]);

  function shiftWeek(direction) {
    if (
      (direction === -1 && left_arrow_clickable) ||
      (direction === 1 && right_arrow_clickable)
    ) {
      const shift_days = direction * 7;
      const end_date_temp = new Date(end_date);

      setSelectedCalendarDate(
        dayjs(new Date(end_date_temp.setDate(end_date.getDate() + shift_days)))
      );
    }
  }

  function onChangeCalendar(new_date) {
    setSelectedCalendarDate(new_date);
    date_formatter(new Date(new_date.$d)) !==
      date_formatter(new Date(selected_calendar_date.$d)) &&
      setShowCalendar(false);
  }

  function close_calendar(e) {
    if (
      check_if_event_within_elements(e, [document.getElementById("calendar")])
    ) {
      return; //return to listener, i.e. listening criteria unfulfilled
    } else {
      setShowCalendar(false);
    }
  }

  useEffect(() => {
    if (show_calendar) {
      window.addEventListener("click", close_calendar);
    } else {
      window.removeEventListener("click", close_calendar);
    }
    return () => {
      window.removeEventListener("click", close_calendar);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show_calendar]);

  function onShowCalendar(e) {
    e.stopPropagation();
    setShowCalendar(!show_calendar);
  }

  return (
    <>
      <div className="training-data-title-outer">
        <div className="training-data-title">
          <div
            className={left_arrow_clickable ? "arrow selectable" : "arrow"}
            onClick={() => shiftWeek(-1)}
          >
            <ArrowBackIcon />
          </div>
          <span
            className="selectable"
            onClick={(e) => {
              onShowCalendar(e);
            }}
          >
            {date_formatter(start_date)}&nbsp;&nbsp;-&nbsp;&nbsp;
            {date_formatter(end_date)}
          </span>
          <div
            className={right_arrow_clickable ? "arrow selectable" : "arrow"}
            onClick={() => shiftWeek(+1)}
          >
            <ArrowForwardIcon />
          </div>
        </div>
      </div>
      {show_calendar && (
        <div id="calendar">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              value={selected_calendar_date}
              onChange={(newDate) => onChangeCalendar(newDate)}
              sx={{
                bgcolor: "white",
                boxShadow: 5,
                borderRadius: 2,
              }}
              minDate={start_of_study__dayjs}
              maxDate={end_of_study__dayjs}
            />
          </LocalizationProvider>
        </div>
      )}
    </>
  );
}

function download(filename, text) {
  var element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(text)
  );
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}
