import React, { useEffect, useRef, useState, Fragment } from "react";
import "../../Main.css";
import { useIQoroStore } from "../../Stores/General";
import { PostData } from "../../API/User";
import {
  date_formatter,
  time_formatter_short,
  msg_user_iqoro_mismatch,
  msg_user_unknown,
  msg_contact_support,
  msg_unit_no_bluetooth_support,
  msg_browser_no_bluetooth_api_support,
  msg_data_read_error,
  msg_no_new_files,
  errorHandling,
  decoder_start_datetime,
  generate_iqoro_log_stream,
  decoder_iqoro_id,
  data_ok,
} from "../Util";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { useTheme } from "@mui/material/styles";
import WarningIcon from "@mui/icons-material/Warning";
import LinearProgress from "@mui/material/LinearProgress";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const iqoro_device_name = "IQoro_1_5";
// const iqoro_device_name = "IQoro1.2";
const transfer_progress_reading_files_done = 95;
const transfer_progress_reading_dir_done = 5;
const transfer_stepper_page_1 = 0;
const transfer_stepper_page_2 = 1;
const transfer_stepper_page_3 = 2;

export function User() {
  const [setCurrentPage, last_sync, setToasterMessage, iqoro_type_in_use] =
    useIQoroStore((state) => [
      state.setCurrentPage,
      state.last_sync,
      state.setToasterMessage,
      state.iqoro_type_in_use,
    ]);

  const check_bluetooth_go_to_transfer_page = async () => {
    if (iqoro_type_in_use === "real") {
      if (navigator.bluetooth) {
        await navigator.bluetooth.getAvailability().then((available) => {
          if (available) {
            setCurrentPage("user-connect-transfer");
          } else {
            setToasterMessage({
              msg: msg_unit_no_bluetooth_support,
              type: "error",
            });
          }
        });
      } else {
        setToasterMessage({
          msg: msg_browser_no_bluetooth_api_support,
          type: "error",
        });
      }
    } else {
      setCurrentPage("user-connect-transfer");
    }
  };

  function CheckLastSync() {
    let text = "";
    let last_sync_text = "";
    if (last_sync.getFullYear() !== 1970) {
      last_sync_text = "Senaste överföringen av data:";

      const last_sync_date = last_sync.getDate();
      const last_sync_temp = new Date(last_sync);
      last_sync_temp.setDate(last_sync_date + 7);

      const current_time = new Date();

      if (last_sync_temp < current_time) {
        text =
          "Det var över en vecka sedan du gjorde en överföring. Gör det gärna nu.";
      }
    }
    return (
      <>
        <br />
        {last_sync_text.length > 0 && (
          <>
            <Card variant="outlined" sx={{ margin: "0 auto", width: "254px" }}>
              <Fragment>
                <CardContent>
                  <div>{last_sync_text}</div>
                  <div className="date-time-display">
                    <CalendarMonthIcon className="date-time-icons" />
                    {date_formatter(last_sync)}
                  </div>
                  <div className="date-time-display">
                    <AccessTimeIcon className="date-time-icons" />
                    {time_formatter_short(last_sync)}
                  </div>
                </CardContent>
              </Fragment>
            </Card>
            <br />
          </>
        )}
        <div>{text}</div>
      </>
    );
  }

  return (
    <>
      <div className="user-stepper-outer">
        <div className="user-stepper-mobile">
          <CheckLastSync />
        </div>
      </div>
      <div className="login-form-button">
        <div
          className="button"
          onClick={() => check_bluetooth_go_to_transfer_page()}
        >
          För över data
        </div>
      </div>
    </>
  );
}

function prepareSendData(toasterMsgFun, contents) {
  let contents_and_datetimes = [];
  let iqoro_id = "";
  let error = false;
  for (let i = 0; i < contents.length; i++) {
    if (data_ok(contents[i])) {
      const start_datetime_iso = decoder_start_datetime(contents[i]);
      if (iqoro_id.length === 0) {
        iqoro_id = decoder_iqoro_id(contents[i]);
        if (iqoro_id.length === 0) {
          toasterMsgFun({
            msg: "Kan inte läsa av iQoro ID från iQoro. " + msg_contact_support,
            type: "error",
          });
          error = true;
          break;
        }
      }

      contents_and_datetimes.push({
        data: contents[i],
        date: start_datetime_iso,
      });
    } else {
      toasterMsgFun({
        msg: "Kan inte läsa av data från iQoro. " + msg_contact_support,
        type: "error",
      });
      error = true;
      break;
    }
  }

  if (error) {
    return { iqoro_id: null, contents_and_datetimes: null };
  } else {
    return { iqoro_id, contents_and_datetimes };
  }
}

function onSendData(
  current_user,
  lastSyncFun,
  showSpinner,
  filesSuccessfullyUploaded,
  iqoro_id,
  contents_and_datetimes
) {
  showSpinner(true);
  return PostData(current_user, iqoro_id, contents_and_datetimes).then(
    (data) => {
      if (data.status === 200) {
        filesSuccessfullyUploaded(true);
        showSpinner(false);
        lastSyncFun(new Date());
      } else {
        const msg = data.message
          ? data.status + ": " + data.message
          : data.status;
        throw new Error(msg);
      }
    }
  );
}

export function UserConnectIQoroAndTransfer() {
  return (
    <TextMobileStepper
      steps={[<BluetoothStep1 />, <BluetoothStep2 />, <BluetoothStep3 />]}
      steps_labels={bluetooth_steps_labels}
    />
  );
}

const bluetooth_steps_labels = ["Koppla", "Data överförs", "Klart"];

function BluetoothStep1() {
  const [
    current_user,
    setActiveStep,
    setCurrentPage,
    setToasterMessage,
    setTransferredFilesRatio,
    setShowSpinner,
    setLastSync,
    files_left_to_fetch,
    setFilesLeftToFetch,
    all_files_ids_to_fetch,
    setAllFilesIDsToFetch,
    iqoro_type_in_use,
    setFilesSuccessfullyUploaded,
    setCpCharacteristic,
    setBluetoothDevice,
  ] = useIQoroStore((state) => [
    state.current_user,
    state.setActiveStep,
    state.setCurrentPage,
    state.setToasterMessage,
    state.setTransferredFilesRatio,
    state.setShowSpinner,
    state.setLastSync,
    state.files_left_to_fetch,
    state.setFilesLeftToFetch,
    state.all_files_ids_to_fetch,
    state.setAllFilesIDsToFetch,
    state.iqoro_type_in_use,
    state.setFilesSuccessfullyUploaded,
    state.setCpCharacteristic,
    state.setBluetoothDevice,
  ]);

  let file_ids = [];
  let nr_of_dir_req = 0;
  const opcode_read = 14;
  let file_contents = new Map();
  const read_characteristic_UUID = "9504be82-e439-11ec-8fea-0242ac120002";
  // const rtc_characteristic_UUID = "9504be90-e439-11ec-8fea-0242ac120002";
  const service_UUID = "9504f00d-e439-11ec-8fea-0242ac120002";
  const control_point_characteristic_UUID =
    "9504be80-e439-11ec-8fea-0242ac120002";
  const buffer = new ArrayBuffer(4);
  const uint8 = new Uint8Array(buffer);
  const opcode_dir = 10;
  const file_type_log_stream = 129;

  const options = {
    filters: [
      {
        services: [service_UUID],
      },
      { name: iqoro_device_name },
    ],
  };

  let bluetooth_device, primary_service, cp_characteristic, read_characteristic;

  // using useRef to be able to read state inside event listener
  const files_left_to_fetch_REF = useRef(files_left_to_fetch);
  function setFilesLeftToFetch_spec(nr) {
    files_left_to_fetch_REF.current = nr;
    setFilesLeftToFetch(nr);
  }

  const all_files_ids_to_fetch_REF = useRef(all_files_ids_to_fetch);
  function setAllFilesIDsToFetch_spec(ids) {
    all_files_ids_to_fetch_REF.current = ids;
    setAllFilesIDsToFetch(ids);
  }

  useEffect(() => {
    setFilesLeftToFetch_spec(-1);

    // Empty msg to detect possible errors in BluetoothStep2
    setToasterMessage({
      msg: "",
      type: "info",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function connectToIQoro_real() {
    await navigator.bluetooth
      .requestDevice(options)
      .then((device) => {
        setShowSpinner(true);
        bluetooth_device = device;
        setBluetoothDevice(device);
        return bluetooth_device.gatt.connect();
      })
      .then((server) => {
        return server.getPrimaryService(service_UUID);
      })
      .then((service) => {
        primary_service = service;
        /*        return primary_service.getCharacteristic(rtc_characteristic_UUID);
      })
      .then((characteristic) => {
        rtc_characteristic = characteristic;
        rtc_characteristic.addEventListener(
          "characteristicvaluechanged",
          handleRTCValueChanged
        );
      })
      .then(() => {
        rtc_characteristic.readValue();
      })
      .then(() => {*/
        return primary_service.getCharacteristic(read_characteristic_UUID);
      })
      .then((characteristic) => {
        read_characteristic = characteristic;
        read_characteristic.addEventListener(
          "characteristicvaluechanged",
          handleCharacteristicValueChanged
        );
      })
      .then(() => {
        read_characteristic.startNotifications();
      })
      .then(() => {
        setActiveStep(transfer_stepper_page_2);
        return primary_service.getCharacteristic(
          control_point_characteristic_UUID
        );
      })
      .then((characteristic) => {
        cp_characteristic = characteristic;
        setCpCharacteristic(characteristic);
        uint8.set([opcode_dir, file_type_log_stream, 0, 0], 0);
        cp_characteristic.writeValueWithResponse(uint8);
      })
      .catch((error) => {
        bluetooth_device.gatt.disconnect();
        setShowSpinner(false);
        setToasterMessage({
          msg: msg_data_read_error + ": " + error + " " + msg_contact_support,
          type: "error",
        });
      });

    async function handleCharacteristicValueChanged(event) {
      let value = event.target.value;
      const header_length = 8;
      let header = "";

      for (let m = 0; m < header_length; m++) {
        header += value.getUint8(m).toString(16).padStart(2, "0");
      }

      if (parseInt(header.substring(0, 2), 16) === opcode_dir) {
        const content_length = 10;
        const nr_of_file_descriptors =
          (value.byteLength - header_length) / content_length;
        for (let j = 0; j < nr_of_file_descriptors; j++) {
          let file_descriptor = "";
          for (
            let i = header_length + j * content_length;
            i < header_length + (j + 1) * content_length;
            i++
          ) {
            file_descriptor += value.getUint8(i).toString(16).padStart(2, "0");
          }
          file_ids.push(
            parseInt(
              file_descriptor.substring(18, 20) +
                file_descriptor.substring(16, 18),
              16
            )
          );
        }

        if (nr_of_file_descriptors === 12) {
          nr_of_dir_req++;
          uint8.set([opcode_dir, file_type_log_stream, nr_of_dir_req, 0], 0);
          await cp_characteristic.writeValueWithResponse(uint8);
        } else if (file_ids.length > 0) {
          setAllFilesIDsToFetch_spec([...file_ids]);
          setFilesLeftToFetch_spec(file_ids.length);
          setTransferredFilesRatio(transfer_progress_reading_dir_done);
          const file_id = file_ids.pop();
          uint8.set([opcode_read, 0, file_id, 0], 0);
          await cp_characteristic.writeValueWithResponse(uint8);
        } else {
          // file_ids.length === 0
          setShowSpinner(false);
          setToasterMessage({
            msg: msg_no_new_files,
            type: "info",
          });
          await bluetooth_device.gatt.disconnect();
        }
      } else if (parseInt(header.substring(0, 2), 16) === opcode_read) {
        const file_id = parseInt(
          header.substring(6, 8) + header.substring(4, 6),
          16
        );
        const block_nr = parseInt(header.substring(2, 4), 16);
        const is_last_block = parseInt(header.substring(10, 12), 16) === 1;

        let block_content = "";
        for (let i = header_length; i < value.byteLength; i++) {
          block_content += value.getUint8(i).toString(16).padStart(2, "0");
        }
        file_contents.set(
          file_id,
          (file_contents.get(file_id) ?? "") + block_content
        );

        if (!is_last_block) {
          const next_block_nr = block_nr + 1;
          uint8.set([opcode_read, next_block_nr, file_id, 0], 0);
          await cp_characteristic.writeValueWithResponse(uint8);
        } else if (file_ids.length > 0) {
          setFilesLeftToFetch_spec(file_ids.length);
          setTransferredFilesRatio(
            ((all_files_ids_to_fetch_REF.current.length - file_ids.length) /
              all_files_ids_to_fetch_REF.current.length) *
              (transfer_progress_reading_files_done -
                transfer_progress_reading_dir_done) +
              transfer_progress_reading_dir_done
          );

          const file_id = file_ids.pop();
          uint8.set([opcode_read, 0, file_id, 0], 0);
          await cp_characteristic.writeValueWithResponse(uint8);
        } else {
          setFilesLeftToFetch_spec(0);
          setTransferredFilesRatio(transfer_progress_reading_files_done);
          const { iqoro_id, contents_and_datetimes } = prepareSendData(
            setToasterMessage,
            [...file_contents].map(([file_id, file_content]) => file_content)
          );
          if (iqoro_id != null && contents_and_datetimes != null) {
            errorHandling(
              onSendData,
              [
                current_user,
                setLastSync,
                setShowSpinner,
                setFilesSuccessfullyUploaded,
                iqoro_id,
                contents_and_datetimes,
              ],
              setToasterMessage,
              [
                { code: "403", text: msg_user_iqoro_mismatch },
                { code: "404", text: msg_user_unknown },
              ],
              setShowSpinner,
              2
            );
          }
        }
      }
    }
    /*
    function handleRTCValueChanged(event) {
      let rtc = event.target.value.getUint32(0, 1);
      let year = (rtc >> 26) + 2022;
      let month = (rtc >> 22) & 0xf;
      let day = (rtc >> 17) & 0x1f;
      let totsecs = rtc & 0x1ffff;
      let hours = (totsecs / 3600) >> 0;
      let mins = ((totsecs % 3600) / 60) >> 0;
      let secs = totsecs % 60;

      console.log(
        ">RTC is " +
          year +
          "-" +
          month +
          "-" +
          day +
          " " +
          hours +
          ":" +
          mins +
          ":" +
          secs
      );
    }*/
  }

  return (
    <>
      <ol>
        <li>
          Starta IQoro 1.5 genom att hålla in tryckknappen (ca 4 sekunder) tills
          det att IQoro 1.5 börjar blinka med grönt sken. Släpp sedan knappen
        </li>
        <br />
        <li>Klicka på 'Hitta IQoro' nedan</li>
        <br />
        <li>
          I listan som kommer upp, tryck på '{iqoro_device_name}' och klicka
          sedan på 'Koppla'
        </li>
      </ol>
      <div className="connect-abort-buttons">
        <div
          id="connect-and-transfer-data-from-iqoro"
          className="button"
          onClick={() =>
            iqoro_type_in_use === "real"
              ? connectToIQoro_real()
              : setActiveStep(transfer_stepper_page_2)
          }
        >
          Hitta iQoro
        </div>
        <div className="button" onClick={() => setCurrentPage("user")}>
          Avbryt
        </div>
      </div>
    </>
  );
}

function BluetoothStep2() {
  const [
    toaster_messages,
    setCurrentPage,
    setShowToaster,
    transferred_files_ratio,
    setTransferredFilesRatio,
    setToasterMessage,
    setShowSpinner,
    files_left_to_fetch,
    all_files_ids_to_fetch,
    setActiveStep,
    setFilesLeftToFetch,
    setAllFilesIDsToFetch,
    setLastSync,
    current_user,
    iqoro_type_in_use,
    files_successfully_uploaded,
    setFilesSuccessfullyUploaded,
    cp_characteristic,
    bluetooth_device,
  ] = useIQoroStore((state) => [
    state.toaster_messages,
    state.setCurrentPage,
    state.setShowToaster,
    state.transferred_files_ratio,
    state.setTransferredFilesRatio,
    state.setToasterMessage,
    state.setShowSpinner,
    state.files_left_to_fetch,
    state.all_files_ids_to_fetch,
    state.setActiveStep,
    state.setFilesLeftToFetch,
    state.setAllFilesIDsToFetch,
    state.setLastSync,
    state.current_user,
    state.iqoro_type_in_use,
    state.files_successfully_uploaded,
    state.setFilesSuccessfullyUploaded,
    state.cp_characteristic,
    state.bluetooth_device,
  ]);

  const buffer = new ArrayBuffer(4);
  const uint8 = new Uint8Array(buffer);
  const opcode_delete = 38;

  // START OF SIM SCENARIO
  const [iqoro_id_sim, setIqoroIdSim] = useState("");
  const [contents_and_datetimes_sim, setContentsAndDatetimesSim] = useState([]);

  const content1 = generate_iqoro_log_stream(
    new Date(2023, 5, 14, 16, 6, 0),
    [
      0, 0.1, -0.1, -0.1, 1, 2, 3, 4, 5, 6, 7, 7, 7, 7, 7, 7, 7, 6, 5, 4, 3, 2,
      1, -0.2, 0.2, 0,
    ]
  );
  const content2 = generate_iqoro_log_stream(
    new Date(2023, 5, 23, 8, 6, 50),
    [
      0, 0.1, -0.1, -0.1, 1, 2, 2.3, 3, 4, 5, 6, 7, 7, 8, 9, 10, 10.4, 9.8, 10,
      10.4, 9.8, 10, 10.4, 9.8, 10, 10.4, 9.8, 10, 10.4, 9.8, 10, 10.4, 9.8, 10,
      10.4, 9.8, 10, 10.4, 9.8, 10, 10.4, 9.8, 10, 10.4, 9.8, 10, 10.4, 9.8, 10,
      10.4, 9.8, 10, 10.4, 9.8, 10, 10.4, 9.8, 10, 10.4, 9.8, 10, 10.4, 9.8, 10,
      10.4, 9.8, 10, 10.4, 9.8, 10, 10.4, 9.8, 10, 10.4, 9.8, 10, 10.4, 9.8, 10,
      10.4, 9.8, 10, 10.4, 9.8, 10, 10.4, 9.8, 10, 10.4, 9.8, 10, 10.4, 9.8, 9,
      8, 7, 6, 5, 4, 3, 2, 1, -0.2, 0.2, 0,
    ]
  );
  const content3 = generate_iqoro_log_stream(
    new Date(2023, 5, 23, 15, 6, 50),
    [
      0, 0.1, -0.1, -0.1, 1, 2, 2.3, 3, 4, 5, 6, 7, 7, 8, 9, 10, 10.4, 9.8, 10,
      10.4, 9.8, 10, 10.4, 9.8, 10, 10.4, 9.8, 10, 10.4, 9.8, 10, 10.4, 9.8, 10,
      10.4, 9.8, 10, 10.4, 9.8, 10, 10.4, 9.8, 10, 10.4, 9.8, 10, 10.4, 9.8, 10,
      10.4, 9.8, 10, 10.4, 9.8, 10, 10.4, 9.8, 10, 10.4, 9.8, 10, 10.4, 9.8, 10,
      10.4, 9.8, 10, 10.4, 9.8, 10, 10.4, 9.8, 10, 10.4, 9.8, 10, 10.4, 9.8, 10,
      10.4, 9.8, 10, 10.4, 9.8, 10, 10.4, 9.8, 10, 10.4, 9.8, 10, 10.4, 9.8, 9,
      8, 7, 6, 5, 4, 3, 2, 1, -0.2, 0.2, 0, 0, 0.1, -0.1, -0.1, 1, 2, 2.3, 3, 4,
      5, 6, 7, 7, 8, 9, 10, 10.4, 9.8, 10, 10.4, 9.8, 10, 10.4, 9.8, 10, 10.4,
      9.8, 10, 10.4, 9.8, 10, 10.4, 9.8, 10, 10.4, 9.8, 10, 10.4, 9.8, 10, 10.4,
      9.8, 10, 10.4, 9.8, 10, 10.4, 9.8, 10, 10.4, 9.8, 10, 10.4, 9.8, 10, 10.4,
      9.8, 10, 10.4, 9.8, 10, 10.4, 9.8, 9, 8, 7, 6, 5, 4, 3, 2, 1, -0.2, 0.2,
      0,
    ]
  );

  useEffect(() => {
    if (iqoro_type_in_use === "sim") {
      if (files_left_to_fetch === -1) {
        let { iqoro_id, contents_and_datetimes } = prepareSendData(
          setToasterMessage,
          [content1, content2, content3, content1, content2, content3]
        );

        if (iqoro_id != null && contents_and_datetimes != null) {
          if (contents_and_datetimes.length === 0) {
            setShowSpinner(false);
            setToasterMessage({
              msg: msg_no_new_files,
              type: "info",
            });
          } else {
            setTimeout(() => {
              setTransferredFilesRatio(transfer_progress_reading_dir_done);
              setIqoroIdSim(iqoro_id);
              setContentsAndDatetimesSim(contents_and_datetimes);
              setAllFilesIDsToFetch(contents_and_datetimes.length);
              setFilesLeftToFetch(contents_and_datetimes.length);
            }, 2000);
          }
        }
      } else if (files_left_to_fetch > 0) {
        const new_files_left = files_left_to_fetch - 1;
        setTimeout(() => {
          if (new_files_left === 0) {
            setTransferredFilesRatio(transfer_progress_reading_files_done);
          } else {
            setTransferredFilesRatio(
              ((all_files_ids_to_fetch.length - new_files_left) /
                all_files_ids_to_fetch.length) *
                (transfer_progress_reading_files_done -
                  transfer_progress_reading_dir_done) +
                transfer_progress_reading_dir_done
            );
          }
          setFilesLeftToFetch(new_files_left);
        }, 1300);
      } else if (files_left_to_fetch === 0) {
        if (iqoro_id_sim && contents_and_datetimes_sim) {
          errorHandling(
            onSendData,
            [
              current_user,
              setLastSync,
              setShowSpinner,
              setFilesSuccessfullyUploaded,
              iqoro_id_sim,
              contents_and_datetimes_sim,
            ],
            setToasterMessage,
            [
              { code: "403", text: msg_user_iqoro_mismatch },
              { code: "404", text: msg_user_unknown },
            ],
            setShowSpinner,
            2
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iqoro_type_in_use, files_left_to_fetch]);
  // END OF SIM SCENARIO

  useEffect(() => {
    async function transfer_ready_and_delete() {
      if (files_successfully_uploaded) {
        setActiveStep(transfer_stepper_page_3);
        setFilesSuccessfullyUploaded(false);
        if (iqoro_type_in_use === "real") {
          for (let i = 0; i < all_files_ids_to_fetch.length; i++) {
            uint8.set([opcode_delete, 0, all_files_ids_to_fetch[i], 0], 0);
            await cp_characteristic.writeValueWithResponse(uint8);
          }
          await bluetooth_device.gatt.disconnect();
        }
      }
    }
    transfer_ready_and_delete();
  }, [files_successfully_uploaded, all_files_ids_to_fetch]);

  function retry() {
    setTransferredFilesRatio(0);
    setShowToaster(false);
    setActiveStep(transfer_stepper_page_1);
  }

  function abort() {
    retry();
    setCurrentPage("user");
  }

  function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

  return toaster_messages[toaster_messages.length - 1].msg === "" ? (
    <>
      <div className="spinner-transfer-data">
        <Box sx={{ width: "100%" }}>
          <LinearProgressWithLabel value={transferred_files_ratio} />
        </Box>
      </div>
      <div className="spinner-transfer-text">
        {files_left_to_fetch === -1 ? (
          <span>Letar upp alla träningspass...</span>
        ) : files_left_to_fetch === 0 ? (
          <span>Sparar träningspass...</span>
        ) : (
          <span>Hämtar {all_files_ids_to_fetch.length} träningspass...</span>
        )}
      </div>
    </>
  ) : toaster_messages[toaster_messages.length - 1].type === "error" ? (
    <>
      <div className="pairing-instruction">
        <div className="warning-icon-failed-to-upload">
          <WarningIcon />
        </div>
        <div className="font-size-16">Oj, något gick fel!</div>
      </div>
      <div className="retry-abort-buttons">
        <div className="button" onClick={retry}>
          Försök igen
        </div>
        <div className="button" onClick={abort}>
          Avbryt
        </div>
      </div>
    </>
  ) : (
    //info or warning
    <div className="login-form-button">
      <div className="button" onClick={abort}>
        Avbryt
      </div>
    </div>
  );
}

function BluetoothStep3() {
  const [setCurrentPage, setActiveStep, setTransferredFilesRatio] =
    useIQoroStore((state) => [
      state.setCurrentPage,
      state.setActiveStep,
      state.setTransferredFilesRatio,
    ]);

  function back() {
    setTransferredFilesRatio(0);
    setCurrentPage("user");
    setActiveStep(transfer_stepper_page_1);
  }

  return (
    <>
      <div>All data har förts över! Nu kan du stänga av iQoro.</div>
      <div className="login-form-button">
        <div className="button" onClick={back}>
          Tillbaka
        </div>
      </div>
    </>
  );
}

function TextMobileStepper({ steps, steps_labels }) {
  const [active_step] = useIQoroStore((state) => [state.active_step]);

  const theme = useTheme();
  const maxSteps = steps_labels.length;
  const panels_background_color = "#b9b9b9";

  return (
    <div className="user-stepper-outer">
      <div className="user-stepper-mobile">
        <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
          <Paper
            square
            elevation={0}
            sx={{
              display: "flex",
              alignItems: "center",
              height: 50,
              pl: 2,
              bgcolor: panels_background_color,
            }}
          >
            <Typography>{steps_labels[active_step]}</Typography>
          </Paper>
          <Box
            sx={{
              height: 255,
              maxWidth: 400,
              width: "100%",
              p: 2,
              marginLeft: "-16px",
            }}
          >
            {steps[active_step]}
          </Box>
          <MobileStepper
            variant="text"
            steps={maxSteps}
            position="static"
            activeStep={active_step}
            sx={{
              bgcolor: panels_background_color,
            }}
            nextButton={
              <Button
                size="small"
                sx={{
                  visibility: "hidden",
                }}
              >
                Next
                {theme.direction === "rtl" ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Button>
            }
            backButton={
              <Button
                size="small"
                sx={{
                  visibility: "hidden",
                }}
              >
                {theme.direction === "rtl" ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft />
                )}
                Back
              </Button>
            }
          />
        </Box>
      </div>
    </div>
  );
}
